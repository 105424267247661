import "./Comment.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import {
    communityFlagCommentById, communityLikeCommentById,
} from "../../app/slices/community/communityActions";
import { commentProps } from "../../app/slices/community/communitySlice";
import CircularImage from "../CircularImage/CircularImage";
import likeButtonActive from "../../assets/like-icon-active.svg";
import likeButtonInctive from "../../assets/like-icon-inactive.svg";
import TimeDistance from "../TimeDistance/TimeDistance";
import { likeCountFormatter } from "../../utils";
import { showReportModalAction2 } from "../../app/slices/application/applicationSlice";
import EllypsisDropdownMenu from "../EllypsisDropdownMenu/EllypsisDropdownMenu";
import { selectUserProfileId } from "../../app/slices/application/applicationSelectors";

export interface CommentProps {
    comment: commentProps;
}

const Comment = (props: CommentProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const myId = useAppSelector(selectUserProfileId)

    const likeComment = (comment: commentProps) => {
        dispatch(communityLikeCommentById({ commentId: comment.commentId, likedByMe: comment.wasLiked }))
    }


    return <div className="comment">
        <div className="comment__user-details">
            <CircularImage isProfileImage={true} src={props.comment.userProfile?.avatarPath} onClick={() => navigate(`/user/${props.comment.userProfile.id}`)} />
            <div className="comment__user-details__right">
                <div className="comment__user-name" onClick={() => navigate(`/user/${props.comment.userProfile.id}`)}>{props.comment.userProfile?.displayName}</div>
                <TimeDistance date={props.comment.datePublished} prefix="Posted" />
            </div>
        </div>
        <div className="comment__content">
            {props.comment.comment}
        </div>
        <div className="comment-actions">
            <div className="comment-actions__left">
                <div className="comment__likes">{likeCountFormatter(props.comment.nrLikes)}</div>
                {props.comment.wasLiked ?
                    <div onClick={() => likeComment(props.comment)} className="comment__interaction-button">
                        <img src={likeButtonActive} />
                        <div className="comment__interaction-button__like-text--active">Liked</div>
                    </div>
                    : <div onClick={() => likeComment(props.comment)} className="comment__interaction-button">
                        <img src={likeButtonInctive} />
                        <div className="comment__interaction-button__like-text--inactive">Like</div>
                    </div>}
            </div>
            <div className="comment-actions__right">
                <EllypsisDropdownMenu targetId={props.comment?.commentId} targetType={"comment"} isReportable={props.comment?.userProfile?.id !== myId} isDeletable={props.comment?.userProfile?.id === myId} />
            </div>
        </div>
    </div>
};

export default Comment;
