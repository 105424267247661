import "./MobileDownloadScreen.css";
import AppleStoreLogo from "../../assets/appstore_logo.png";
import CTAAppleStore from "../../assets/cta_appstore.png";
import GooglePlayLogo from "../../assets/googleplay_logo.png";
import CTAPlayStore from "../../assets/cta_playstore.png";

interface MobileDownloadScreenProps {
    os: "android" | "ios"
}

const MobileDownloadScreen = (props: MobileDownloadScreenProps) => {
    return <div className="mobile-download__identify_text_buttons">
        <div className="mobile-download__identify_text">
            <h2>To join and use the Earth Community on your device get our free app</h2>
        </div>
        <div className={"mobile-download___store-buttons"}>
            {props.os === "ios" && <a
                href="https://apps.apple.com/us/app/earthsnap-nature-identifier/id1590178460"
                target="__blank"
            >
                <div className="mobile-download__store-button">
                    <img src={AppleStoreLogo} />
                    <img src={CTAAppleStore} />
                </div>
            </a>}
            {props.os === "android" && <a href="https://play.google.com/store/apps/details?id=com.earthsnap" target="__blank">
                <div className="mobile-download__store-button">
                    <img src={GooglePlayLogo} />
                    <img src={CTAPlayStore} />
                </div>
            </a>}
        </div>
    </div>
};

export default MobileDownloadScreen;