import {ApiService} from './api.service';
import {CountriesDTO, NotificationTokenDTO, UpdateCountryDTO, UserProfileDTO} from './api/users/users.dto';
import {
    SpeciesBulkResponseDTO,
    SpeciesDTO,
    SpeciesSearchRequestDTO,
    SpeciesSearchResponseDTO,
} from './api/species/species.dto';
import {ConfigApiDTO, ConfigPathsDTO} from './api/config/config.dto';

class _EarthID {
    private apiService: typeof ApiService;

    constructor() {
        this.apiService = ApiService;
    }

    public setUser(user: any) {
        this.apiService.setUser(user);
    }

    /**
     * Used to get a user's profile
     */
    public async getUserProfile(): Promise<UserProfileDTO> {
        const data = await ApiService.getProfile<UserProfileDTO>();
        return new UserProfileDTO(data.data);
    }

    //Species

    /**
     * Used to search a species by its hash
     * @param hash
     */
    public async getSpeciesByHash(hash: string, kind: string): Promise<SpeciesDTO> {
        const data = await ApiService.getSpeciesByHash<SpeciesDTO>(hash, kind);
        return new SpeciesDTO(data.data);
    }

    /**
     * Gets all the paths needed to run the app.
     *
     * For example, the url for the Cloudfront CDN. Needs to be prepended to the user's profile image url.
     *
     * DEPRECATED
     */
    public async getConfigurationData(): Promise<ConfigPathsDTO> {
        const data = await ApiService.getConfigurationData<any>();
        return new ConfigPathsDTO(data.data);
    }

    /**
     * Used to search a species by the exact species name (species field)
     * @param dto
     */
    public async getSpeciesByName(dto: SpeciesSearchRequestDTO): Promise<SpeciesDTO> {
        const data = await ApiService.getSpeciesByName<SpeciesSearchRequestDTO, SpeciesDTO>(dto);
        return new SpeciesDTO(data.data);
    }

    /**
     * Used to search multiple species by a search term.
     * Will match words in the description, synonyms, common names, and most of the fields from the species object.
     * @param dto
     */
    public async searchSpecies(dto: SpeciesSearchRequestDTO): Promise<SpeciesSearchResponseDTO> {
        const data = await ApiService.searchSpecies<SpeciesSearchRequestDTO, SpeciesSearchResponseDTO>(dto);
        return new SpeciesSearchResponseDTO(data.data);
    }

    /**
     * Used to search multiple species by a search term.
     * Will match words in the description, synonyms, common names, and most of the fields from the species object.
     * @param dto
     */
    public async resolveBulkSpecies(kind: string, ids: string): Promise<SpeciesBulkResponseDTO> {
        const data = await ApiService.resolveSpeciesBulk<SpeciesDTO>(kind, ids);
        return new SpeciesBulkResponseDTO(data.data);
    }


    /**
     * Check current api version.
     *
     */
    public async checkApiVersion(payload: ConfigApiDTO): Promise<boolean> {
        const data = await ApiService.checkApiVersion<ConfigApiDTO, boolean>(payload);
        return data.data;
    }

    /**
     * Used to update the user's profile picture.
     */
    public async updateUserProfilePicture(image: any): Promise<any> {
        const data = await ApiService.updateUserProfilePicture<any, any>({ image: image });
        return new UserProfileDTO(data.data);
    }

    /**
     * Used to update the user's display name.
     */
    public async updateUserDisplayName(displayName: string): Promise<any> {
        const data = await ApiService.updateUserDisplayName<any, any>({ displayName: displayName });
        return new UserProfileDTO(data.data);
    }
}

export const EarthID = new _EarthID();
