import React, {useState} from "react";
import "./MyProfileScreen.css";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    selectIsCommunityProfileLoading,
    selectMyDisplayName,
    selectMyFeed,
    selectMyFeedNextPage,
    selectMyJoinDate
} from "../../app/slices/community/communitySelectors";
import {
    selectUserProfileCountryCode,
    selectUserProfileId,
    selectUserProfileImage
} from "../../app/slices/application/applicationSelectors";
import {getCommunityProfile, getCommunityUserFollowInfo} from "../../app/slices/community/communityActions";
import {useNavigate} from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Post from "../../components/Post/Post";
import CircularImage from "../../components/CircularImage/CircularImage";
import noPostsIcon from "../../assets/newspaper-icon.svg";
import LoadMoreButton from "../../components/LoadMoreButton/LoadMoreButton";
import Flag from 'react-world-flags';
import {followersCountFormatter} from "../../utils";
import TimeDistance from "../../components/TimeDistance/TimeDistance";

export const MyProfileScreen = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [followersCount, setFollowersCount] = useState<any>(0);

    const myFeed = useAppSelector(selectMyFeed);
    const myDisplayName = useAppSelector(selectMyDisplayName);
    const myUserId = useAppSelector(selectUserProfileId);
    const myProfileImage = useAppSelector(selectUserProfileImage);
    const myJoinDate = useAppSelector(selectMyJoinDate);
    const countryFlag = useAppSelector(selectUserProfileCountryCode);
    const nextPage = useAppSelector(selectMyFeedNextPage);
    const loading = useAppSelector(selectIsCommunityProfileLoading);

    React.useEffect(() => {
        dispatch(getCommunityUserFollowInfo(myUserId || ""))
            .unwrap()
            .then(data => {
                setFollowersCount((data as any).followerCount);
            });
    }, []);

    const postsToRender = myFeed;
    const displayNameToRender = myDisplayName;

    const onLoadMoreVisible = data => {
        if (data) {
            loadPosts();
        }
    };

    const loadPosts = () => {
        if (nextPage) {
            dispatch(getCommunityProfile({page: nextPage}))
        }
    }

    if (loading) {
        return (
            <div><Loader/></div>
        )
    }

    return (
        <div className="MyProfileScreen">
            <div className="user__user-details">
                <CircularImage isProfileImage={true} src={myProfileImage}/>
                <div className="user__user-details__right">
                    <div className="user__user-name">{myDisplayName}</div>
                    <div className="user__joined-ago"><TimeDistance date={myJoinDate} prefix="Joined"/> <span></span>
                    </div>
                </div>
                <div>
                    <button onClick={()=>{navigate('/edit-my-profile')}} className="MyProfileScreenEditProfileButton" >Edit profile</button>
                </div>
            </div>
            <div className="user__followers">{followersCountFormatter(followersCount)}</div>
            {countryFlag && <div className="user__country"><Flag code={countryFlag}/></div>}

            <div className="profile-screen__timeline-label">Timeline</div>
            {!postsToRender && <div className="user__no-posts">
                <img src={noPostsIcon}/>
                <div className="user__no-posts__text">No posts</div>
            </div>}
            <div className="user-screen__posts-section"> {postsToRender?.map(post => {
                return (
                    <Post {...post} />
                )
            })}</div>

            {nextPage &&
                <LoadMoreButton onClick={onLoadMoreVisible}>Load More</LoadMoreButton>
            }
        </div>
    )
}
