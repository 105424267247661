import React, { useEffect } from "react";
import "./GroupScreen.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    joinGroup,
    leaveGroup,
    loadCommunityById,
    loadCommunityPosts
} from "../../app/slices/community/communityActions";
import {
    selectGroupScreenData,
    selectGroupScreenIsLoadingData,
    selectGroupScreenIsLoadingPosts,
    selectGroupScreenNextPage,
    selectGroupScreenPosts
} from "../../app/slices/community/communitySelectors";
import Loader from "../../components/Loader/Loader";
import Post from "../../components/Post/Post";
import CircularImage from "../../components/CircularImage/CircularImage";
import { kFormatter } from "../../utils";
import mustJoinGroupIcon from "../../assets/newspaper-icon.svg";
import LoadMoreButton from "../../components/LoadMoreButton/LoadMoreButton";

export interface GroupScreenProps {
}

export const GroupScreen = (props: GroupScreenProps) => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const groupId = params.id || "";

    const posts = useAppSelector(selectGroupScreenPosts);
    const group = useAppSelector(selectGroupScreenData);
    const loadingPost = useAppSelector(selectGroupScreenIsLoadingPosts);
    const loadingPage = useAppSelector(selectGroupScreenIsLoadingData);

    const nextPage = useAppSelector(selectGroupScreenNextPage);

    const isGroupJoined = group?.joined === true;
    let postsToRender = isGroupJoined ? [...posts] : [];


    useEffect(() => {
        dispatch(loadCommunityById(groupId || ''));
        loadPosts(true);
    }, [groupId])

    useEffect(() => {
        const loadInitial = async () => {
            await dispatch(loadCommunityById(groupId));
            loadPosts(true);
        }

        loadInitial();

    }, []);

    const onJoinTogglePressed = () => {
        if (group) {
            if (group.joined) {
                dispatch(leaveGroup(group.communityId));
            } else {
                dispatch(joinGroup(group.communityId));
            }
        }
    };

    const loadPosts = (refresh?: boolean) => {
        dispatch(loadCommunityPosts({ id: groupId, page: nextPage, forceRefresh: refresh }));
    };

    const onLoadMoreVisible = data => {
        if (data && nextPage?.before) {
            loadPosts();
        }
    };

    if(loadingPost || loadingPage){
        return (
            <Loader/>
        )
    }

    return (
        <div className="GroupScreen">
            <div className="groupScreen__header">
                <div className="group-details">
                    <CircularImage isProfileImage={false} src={group?.avatarFileId} />
                    <div className="group-details__right">
                        <div className="group-name">{group?.displayName}</div>
                        <div className="group-type">{group?.type}</div>
                    </div>
                </div>
                <div className="group-metrics">
                    <div><span>{kFormatter(group?.postsCount || 0)}</span> {group?.postsCount === 1 ? "post" : "posts"}</div>
                    <div><span>{kFormatter(group?.membersCount || 0)}</span> {group?.membersCount === 1 ? "member" : "members"}</div>
                </div>
                <div className="group-description">{group?.description}</div>
                <div className="group__join-button__container">
                    {!isGroupJoined &&
                        <button className="group__join-button" onClick={onJoinTogglePressed}>JOIN COMMUNITY</button>
                    }
                    {isGroupJoined &&
                        <button className="group__join-button" onClick={onJoinTogglePressed}>LEAVE COMMUNITY</button>
                    }
                </div>
                <div className="group-header__timeline-label">Timeline</div>
                {!isGroupJoined &&
                    <div className="must-join-group">
                        <img src={mustJoinGroupIcon} />
                        <div className="must-join-group__text">Join this community to see the posts.</div>
                    </div>
                }
            </div>
            <div className="group-screen__posts-section">
                {postsToRender.map(post => {
                    return (
                       <Post {...post} />
                    )
                })}
            </div>


            {(nextPage && isGroupJoined && !loadingPost) &&
                <LoadMoreButton onClick={onLoadMoreVisible}>Load More</LoadMoreButton>
            }

            {(loadingPost || loadingPage) &&
                <Loader />
            }
        </div>
    )
}
