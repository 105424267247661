import * as A from '@amityco/ts-sdk';

export const getRecommendedCommunities = async (): Promise<Amity.Community[]> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.getRecommendedCommunities), res => {
      const { loading, data, error } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data ?? []);
    });
  });
};

export const getTopTrendingCommunities = async (): Promise<Amity.Community[]> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.getTopTrendingCommunities), res => {
      const { loading, data, error } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data ?? []);
    });
  });
};

export const getCategory = async (categoryId?: string): Promise<Amity.Category | undefined> => {
  return new Promise((resolve, reject) => {
    if (!categoryId) return resolve(undefined);
    A.runQuery(A.createQuery(A.getCategory, categoryId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const queryCategories = async (query?: Parameters<typeof A.queryCategories>[0]): Promise<Amity.Category[]> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.queryCategories, query), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data ?? []);
    });
  });
};

export const queryCommunities = async (
  query: Parameters<typeof A.queryCommunities>[0],
): Promise<{ data: Amity.Community[]; nextPage: Amity.Page | undefined }> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.queryCommunities, query), res => {
      const { loading, data, error, nextPage } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve({ data: data ?? [], nextPage });
    });
  });
};

export const getCommunity = async (communityId: string): Promise<Amity.Community | undefined> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.getCommunity, communityId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const getFile = async (fileId?: string): Promise<Amity.File | undefined> => {
  return new Promise((resolve, reject) => {
    if (!fileId) return resolve(undefined);
    A.runQuery(A.createQuery(A.getFile, fileId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const getUser = async (userId: string): Promise<Amity.User | undefined> => {
  return new Promise((resolve, reject) => {
    if (!userId) return resolve(undefined);
    A.runQuery(A.createQuery(A.getUser, userId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const getUserFollowInfo = async (userId: string): Promise<Amity.FollowInfo | undefined> => {
  return new Promise((resolve, reject) => {
    if (!userId) return resolve(undefined);
    A.runQuery(A.createQuery(A.getFollowInfo, userId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const followUser = async (userId: string): Promise<Amity.FollowStatus | undefined> => {
  return new Promise((resolve, reject) => {
    if (!userId) return resolve(undefined);
    A.runQuery(A.createQuery(A.follow, userId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const unFollowUser = async (userId: string): Promise<Boolean | undefined> => {
  return new Promise((resolve, reject) => {
    if (!userId) return resolve(undefined);
    A.runQuery(A.createQuery(A.unfollow, userId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const updateUserAvatar = async (userId: string, avatarPath: string): Promise<Amity.User | undefined> => {
  return new Promise((resolve, reject) => {
    if (!userId) return resolve(undefined);
    const data = { avatarCustomUrl: avatarPath };

    A.runQuery(A.createQuery(A.updateUser, userId, data), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const updateUserDisplayName = async (userId: string, displayName: string): Promise<Amity.User | undefined> => {
  return new Promise((resolve, reject) => {
    if (!userId) return resolve(undefined);
    const data = { displayName: displayName };

    A.runQuery(A.createQuery(A.updateUser, userId, data), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const updateUserCountryFlag = async (userId: string, countryFlag: string): Promise<Amity.User | undefined> => {
  return new Promise((resolve, reject) => {
    if (!userId) return resolve(undefined);
    const data = { metadata: { countryFlag: countryFlag } };

    A.runQuery(A.createQuery(A.updateUser, userId, data), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const getPost = async (postId: string): Promise<Amity.Post> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.getPost, postId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const getPosts = async (postId: string[]): Promise<Amity.Post[]> => {
  return new Promise((resolve, reject) => {
    if (postId.length === 0) return resolve([]);
    A.runQuery(A.createQuery(A.getPosts, postId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const queryPosts = async (
  query: Parameters<typeof A.queryPosts>[0],
): Promise<{ data: Amity.Post[]; nextPage: Amity.PageRaw | undefined }> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.queryPosts, query), res => {
      const { loading, error, data, nextPage } = res;
      if (error) return reject(error);
      if (loading) return;

      resolve({ data: data ?? [], nextPage });
    });
  });
};

export const queryGlobalFeed = async (
  page?: Amity.Page,
): Promise<{ data: Amity.Post[]; nextPage: Amity.Page | undefined }> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.queryGlobalFeed, { page, useCustomRanking: true }), res => {
      const { loading, error, data, nextPage } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve({ data: data ?? [], nextPage });
    });
  });
};

export const queryComments = async (
  query: Parameters<typeof A.queryComments>[0],
): Promise<{ data: Amity.Comment[]; nextPage: Amity.Page | undefined; prevPage: Amity.Page | undefined }> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.queryComments, query), res => {
      const { loading, error, data, nextPage, prevPage } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve({ data: data ?? [], nextPage, prevPage });
    });
  });
};

export const deleteComment = async (commentId: string): Promise<Amity.Comment> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.deleteComment, commentId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      // @ts-ignore
      resolve(res.data);
    });
  });
};

export const deletePost = async (postId: string): Promise<Amity.Post> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.deletePost, postId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      // @ts-ignore
      resolve(res.data);
    });
  });
};

export const reportPost = async (postId: string): Promise<boolean | undefined> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.createReport, 'post', postId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const reportComment = async (commentId: string): Promise<boolean | undefined> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.createReport, 'comment', commentId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const reportUser = async (userId: string): Promise<boolean | undefined> => {
  return new Promise((resolve, reject) => {
    A.runQuery(A.createQuery(A.createReport, 'user', userId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const joinCommunity = async (communityId?: string): Promise<boolean | undefined> => {
  return new Promise((resolve, reject) => {
    if (!communityId) return resolve(undefined);
    A.runQuery(A.createQuery(A.joinCommunity, communityId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};

export const leaveCommunity = async (communityId?: string): Promise<boolean | undefined> => {
  return new Promise((resolve, reject) => {
    if (!communityId) return resolve(undefined);
    A.runQuery(A.createQuery(A.leaveCommunity, communityId), res => {
      const { loading, error, data } = res;
      if (error) return reject(error);
      if (loading) return;
      resolve(data);
    });
  });
};
