import "./RegisterScreen.css";
import AppleStoreLogo from "../../assets/appstore_logo.png";
import CTAAppleStore from "../../assets/cta_appstore.png";
import GooglePlayLogo from "../../assets/googleplay_logo.png";
import CTAPlayStore from "../../assets/cta_playstore.png";


const RegisterScreen = () => {
    return <div className="register-screen">
        <div className="register-screen__container">
            <p>To register an account please install Earthsnap on your mobile device</p>
            <div className={"register-screen__store-buttons"}>
                <a
                    href="https://apps.apple.com/us/app/earthsnap-nature-identifier/id1590178460"
                    target="__blank"
                >
                    <div className="register-screen__store-button">
                        <img src={AppleStoreLogo} />
                        <img src={CTAAppleStore} />
                    </div>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.earthsnap" target="__blank">
                    <div className="register-screen__store-button">
                        <img src={GooglePlayLogo} />
                        <img src={CTAPlayStore} />
                    </div>
                </a>
            </div>
        </div>
    </div>
}
export default RegisterScreen;