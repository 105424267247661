import React, { useState } from "react";
import "./NewPostScreen.css";
import NewPostGroupSelector from "../../components/NewPostGroupSelector/NewPostGroupSelector";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    isCreatingPost,
    selectAllCommunities,
    selectCommunityTarget
} from "../../app/slices/community/communitySelectors";
import {
    createImagePost,
    createTextPost, getCommunityProfile,
    loadCommunityById,
    loadCommunityPosts
} from "../../app/slices/community/communityActions";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import placeholderImage from "../../assets/new-post-image-placeholder.png";
import uploadImageIcon from "../../assets/upload_image_icon.svg";

export interface NewPostScreenProps {
}

const NewPostScreen = (props: NewPostScreenProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [file, setFile] = useState<any>(null);
    const [image, setImage] = useState<any>(placeholderImage);
    const [uri, setUri] = useState<any>(null);
    const [imageType, setImageType] = React.useState<string | undefined>();
    const [imageName, setImageName] = React.useState<string | undefined>();

    const [text, setText] = useState<string>("");

    const allGroups = useAppSelector(selectAllCommunities);
    const groupsIJoined = allGroups.filter(g => g.isJoined);
    const targetCommunity = useAppSelector(selectCommunityTarget);
    const targetCommunityId = targetCommunity.communityId;
    const targetCommunityIsFeed = targetCommunity.onMyFeed;
    const canCreatePost =
        ((targetCommunityIsFeed === true || targetCommunityId?.length) && text.length > 0) === true;

    const createNewPost = async () => {
        if (canCreatePost) {
            if (image) {
                await dispatch(
                    createImagePost({
                        isCreatingPost: false,
                        postImagePath: uri,
                        postImageType: imageType,
                        postImageName: imageName,
                        postImageFile: file,
                        postText: text,
                        postTargetGroupId: targetCommunityId,
                        postOnMyWall: targetCommunityIsFeed,
                    }),
                );
            } else {
                await dispatch(
                    createTextPost({
                        isCreatingPost: false,
                        postText: text,
                        postTargetGroupId: targetCommunityId,
                        postOnMyWall: targetCommunityIsFeed,
                    }),
                );
            }

            if (targetCommunityId) {
                dispatch(loadCommunityById(targetCommunityId));
                dispatch(loadCommunityPosts({ id: targetCommunityId, page: { limit: 10 } }));
                navigate(`/group/${targetCommunityId}`);
            }
            if (targetCommunityIsFeed) {
                navigate('/my-profile');
                dispatch(getCommunityProfile({ forceRefreshPosts: true }));
            }
        }
    };

    const loading = useAppSelector(isCreatingPost);

    const inputField = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    const onFileSelected = async (e) => {
        const file = e.target.files[0];
        const data = await readFile(file);
        const uri = URL.createObjectURL(file);
        setUri(uri);
        setFile(file);
        setImage(data);
        setImageType(file.type);
        setImageName(file.name);
    }

    const readFile = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            const url = reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                return resolve(reader.result);
            }
        })
    }

    if (loading) {
        return (
            <div style={{ height: '100%' }}>
                <h2>Creating post...</h2>
            </div>
        )
    }

    return (
        <div className="NewPostScreen">
            <h3>Add an image:</h3>
            <div className="new-post__image-input-container">
                <img src={image} />
                <div className="new-post__button-container">
                    <button onClick={() => inputField.current.click()}>
                        <img src={uploadImageIcon} />
                    </button>
                </div>
                <input accept="image/*" type='file' id='file' ref={inputField} style={{ display: 'none' }}
                    onChange={onFileSelected} />
            </div>

            <div className="new-post__text">
            <h3>Write here:</h3>
                <textarea placeholder="Write here..." value={text} onChange={(e) => setText(e.target.value)} cols={60} rows={20} />
            </div>

            <div className="new-post__post-to">
                <h3>Post to:</h3>
                <NewPostGroupSelector selectedTarget={targetCommunity}
                    availableGroups={groupsIJoined} />
            </div>

            <div className="new-post__create-post-button__container">
                <button className="new-post__create-post-button" disabled={!canCreatePost} onClick={createNewPost}>Create post</button>
            </div>
        </div>
    )
}

export default NewPostScreen;
