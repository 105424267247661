import royal from "../assets/mockups/Elon_Musk_Royal_Society.png";
import ecovillage from "../assets/mockups/Ecovillage.png";
import greenLiving from "../assets/mockups/SuzeRose.png";


export const communitiesMockupData =
    [
        {
            id: "01",
            image: royal,
            name: "Royal Society",
        },
        {
            id: "02",
            image: ecovillage,
            name: "Ecovillage",
        },
        {
            id: "03",
            image: greenLiving,
            name: "GreenLiving",
        },
        {
            id: "04",
            image: royal,
            name: "Royal Society",
        },
        {
            id: "05",
            image: ecovillage,
            name: "Ecovillage",
        },
        {
            id: "06",
            image: greenLiving,
            name: "GreenLiving",
        },
    ];
