import { RootState } from '../../store';

/**
 * These are selectors that allow us to pick some values from this slice.
 * They make the code more readable and avoid getting the entire slice all the time.
 */
export const selectAppInit = (state: RootState) => state.application.init;

export const selectIsLoggedIn = (state: RootState) => state.application.userLoggedIn;

export const selectUserProfile = (state: RootState) => state.application.profile;

export const selectUserLoggedInWithSocial = (state: RootState) =>
  state.application.auth?.providerData[0]?.providerId !== 'password';

export const selectResetPasswordButtonState = (state: RootState) => state.application.hideMailResetButton;

export const selectUserProfileCountryCode = (state: RootState) => state.application.profile?.country?.code ?? undefined;
export const selectUserProfileEmail = (state: RootState) => state.application.profile?.email;
export const selectUserDisplayName = (state: RootState) => state.application.profile?.displayName ?? '';
export const selectShowLoadingIndicator = (state: RootState) => state.application.showLoadingIndicator;
export const selectUserProfileImage = (state: RootState) =>
  state.application.profile?.profileImage ?? 'placeholder.jpg';
export const selectUserProfileId = (state: RootState) => state.application.profile?.userId;
export const selectAuthWithEmailErrorMessage = (state: RootState) => state.application.authErrorMessage;

export const selectResetPasswordMessage = (state: RootState) => state.application.resetPasswordMessage;

export const selectUserId = (state: RootState) => state.application.profile?.userId ?? '';
export const selectFirebaseToken = (state: RootState) => state.application.firebaseToken;
export const selectApplicationState = (state: RootState) => state.application.applicationState;
export const selectUserLoggedIn = (state: RootState) => state.application.userLoggedIn;
export const selectUserAuthFlowComplete = (state: RootState) => state.application.authFlowComplete;
export const selectCommunityConnected = (state: RootState) => state.application.communityConnected;

export const selectReportModalData = (state:RootState) =>state.application.reportModalData;
export const selectDeleteModalData = (state:RootState) =>state.application.deleteModalData;
