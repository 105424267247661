    export const kFormatter = (num: number) => {
        return num > 999 ? `${((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num)
    };

    export const membersCountFormatter = (membersCount) => {
        return `${kFormatter(membersCount || 0)} ${membersCount === 1 ? "member" : "members"}`
    }

    export const commentsCountFormatter = (commentsCount) => {
        return `${kFormatter(commentsCount || 0)} ${commentsCount === 1 ? "comment" : "comments"}`
    }

    export const likeCountFormatter = (likeCount) => {
        return `${kFormatter(likeCount || 0)} ${likeCount === 1 ? "like" : "likes"}`
    }

    export const followersCountFormatter = (followersCount) => {
        return `${kFormatter(followersCount || 0)} ${followersCount === 1 ? "follower" : "followers"}`
    }