import { SpeciesType } from './species.model';

export class SpeciesDTO {
  id: string;
  title: string;
  description?: string;
  species: string;
  url: string;
  type: SpeciesType;
  imaggaUNIQUEid: string;
  image?: string;
  images?: string[];
  common_names?: string[];
  synonyms?: string[];
  family?: string;
  kingdom?: string;
  subkingdom?: string;
  superdivision?: string;
  division?: string;
  class?: string;
  subclass?: string;
  order?: string;
  genus?: string;
  group?: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class SpeciesSearchRequestDTO {
  name: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class SpeciesSearchResultDTO {
  label: string;

  species: string;

  hash: string;

  type: SpeciesType;

  url: string;

  image?: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class SpeciesSearchResponseDTO {
  results: SpeciesSearchResultDTO[];

  constructor(data: any) {
    this.results = data?.results?.map((item: any) => new SpeciesSearchResultDTO(item));
  }
}

export class SpeciesBulkResponseDTO {
  results: SpeciesDTO[];

  constructor(data: any) {
    Object.assign(this, data);
    this.results = data?.results?.map((item: any) => new SpeciesDTO(item));
  }
}
