import "./Navbar.css";
import earthsnapLogo from "../../assets/earthsnap-logo.png"
import communityLogo from "../../assets/community-logo.png"
import CircularImage from "../CircularImage/CircularImage";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectUserLoggedIn, selectUserProfileImage} from "../../app/slices/application/applicationSelectors";
import {selectMyDisplayName} from "../../app/slices/community/communitySelectors";
import {postLogoutDataWipe} from "../../app/slices/application/applicationActions";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
    const dispatch = useAppDispatch();
    const navigate=useNavigate();

    const isLoggedIn = useAppSelector(selectUserLoggedIn);

    const myProfileImage = useAppSelector(selectUserProfileImage);
    const myDisplayName = useAppSelector(selectMyDisplayName);

    const onLogoutClicked = () => {
        dispatch(postLogoutDataWipe());
    }

    return <div className="navbar">
        <div className="navbar-left">
            <div className="earthsnap-logo">
                <img style={{height:'40px'}} src='https://www.earth.com/navigation/earth_logo_tagline_white.svg' alt="earthsnap logo" />
            </div>

            <div className="community-logo">
                <img src={communityLogo} alt="community logo" />
                <p>CHAT</p>
            </div>

            <a href="https://www.earth.com" target="_blank" className="navbar-link">EARTH.COM</a>

        </div>

        {isLoggedIn &&
        <div className="navbar-right">
            <div onClick={()=>navigate("/my-profile")} className="navbar-displayName">{myDisplayName}</div>
            <CircularImage onClick={()=>navigate("/my-profile")} isProfileImage={true} src={myProfileImage} withBorder/>
            <div onClick={onLogoutClicked} className="navbar-logout">Logout</div>
        </div>
        }
    </div>
}

export default Navbar;
