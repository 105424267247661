import {createAsyncThunk} from '@reduxjs/toolkit';
import * as A from '@amityco/ts-sdk';
import {
    commentProps,
    CommunityProps,
    CommunityTarget,
    CreatePostProps,
    GroupsProps,
    postProps,
} from './communitySlice';
import {
    deleteComment,
    deletePost,
    followUser,
    getCategory,
    getCommunity,
    getFile,
    getPost,
    getPosts,
    getRecommendedCommunities,
    getTopTrendingCommunities,
    getUser,
    getUserFollowInfo,
    joinCommunity,
    leaveCommunity,
    queryComments,
    queryCommunities,
    queryGlobalFeed,
    queryPosts,
    reportComment,
    reportPost,
    reportUser,
    unFollowUser,
} from '../../amity/functions';
import {REACTION} from '../../amity/const';
import {ApplicationState} from '../application/applicationSlice';
import {AppCache, CACHE_EXPIRATIONS, CACHE_KEYS, CacheItem} from "../../cache";

const categoriesData = [
    {
        id: 0,
        image: 'https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg',
        name: 'Digital Prog...',
    },
    {
        id: 1,
        image: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Elon_Musk_Royal_Society_%28crop2%29.jpg',
        name: 'Elon Musk',
    },
    {
        id: 2,
        image:
            'https://yt3.ggpht.com/jLB0BgKzW4L6UxIUeSGLcmQi-3mUrXdRKa8JoljAaLtFBX8lK2vUlQqFDO4NDAHeiBSho5Jog3w=s900-c-k-c0x00ffffff-no-rj',
        name: 'Netflix',
    },
];

const categoryGroups = [
    {
        id: 0,
        image: 'https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg',
        name: 'Digital Prog...',
    },
    {
        id: 1,
        image: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Elon_Musk_Royal_Society_%28crop2%29.jpg',
        name: 'Elon Musk',
    },
];

export const loadCommunityById = createAsyncThunk('community/loadCommunityById', async (id: string) => {
    const community = await getCommunity(id);
    const avatarFile = await getFile(community?.avatarFileId);
    const category = await getCategory(community?.categoryIds?.[0]);
    const payloadData: CommunityProps = {
        communityId: community?.communityId,
        avatarFileId: avatarFile?.fileUrl,
        displayName: community?.displayName,
        description: community?.description,
        postsCount: community?.postsCount,
        membersCount: community?.membersCount,
        joined: community?.isJoined,
        type: category?.name,
    };
    return payloadData;
});

export const loadCommunityPosts = createAsyncThunk(
    'community/loadCommunityPosts',
    async (data: { id: string; page?: any; forceRefresh?: boolean }) => {
        const {data: postList, nextPage} = await queryPosts({
            targetType: 'community',
            targetId: data.id,
            page: data.forceRefresh ? null : data.page,
            isDeleted: false,
        });
        const posts = await Promise.all(
            postList.map(async (p: Amity.Post) => {
                const postedUser = await getUser(p.postedUserId);
                const avatarFile = await getFile(postedUser?.avatarFileId);
                const childrenPost = await getPosts(p.children);
                let postImage: string | undefined = undefined;
                let postImageFull: string | undefined = undefined;
                if (childrenPost.length > 0) {
                    const childrenFile = await getFile((childrenPost[0].data as any).fileId);
                    postImage = childrenFile?.fileUrl + '?size=medium';
                    postImageFull = childrenFile?.fileUrl + '?size=full';
                }

                const post: postProps = {
                    postId: p.postId,
                    userProfile: {
                        id: postedUser?.userId,
                        displayName: postedUser?.displayName,
                        avatarPath: postedUser?.avatarCustomUrl,
                    },
                    datePublished: p.createdAt,
                    postText: (p.data as any).text,
                    postImage,
                    postImageFull,
                    postType: p?.metadata?.type,
                    postSpeciesHash: p?.metadata?.speciesHash,
                    postSpeciesTitle: p?.metadata?.speciesTitle,
                    nrComments: p.commentsCount,
                    targetId: p.targetId,
                    nrLikes: p.reactionsCount,
                    wasLiked: p.myReactions?.includes(REACTION.LIKE) ?? false,
                };
                return post;
            }),
        );
        return {posts, nextPage};
    },
);

export const getNewsFeed = createAsyncThunk(
    'community/getNewsFeed',
    async (data: { page?: any; refresh?: boolean }) => {
        const {data: feedList, nextPage} = await queryGlobalFeed(data.refresh ? {after: 0, limit: 10} : data.page);
        const posts = await Promise.all(
            feedList.map(async p => {
                const postedUser = await getUser(p.postedUserId);
                const childrenPost = await getPosts(p.children);
                let postImage: string | undefined = undefined;
                let postImageFull: string | undefined = undefined;
                if (childrenPost.length > 0) {
                    const childrenFile = await getFile((childrenPost[0].data as any).fileId);
                    postImage = childrenFile?.fileUrl + '?size=medium';
                    postImageFull = childrenFile?.fileUrl + '?size=full';
                }
                const post: postProps = {
                    postId: p.postId,
                    userProfile: {
                        id: postedUser?.userId,
                        displayName: postedUser?.displayName,
                        avatarPath: postedUser?.avatarCustomUrl,
                    },
                    datePublished: p.createdAt,
                    postText: (p.data as any).text,
                    postImage,
                    postImageFull,
                    postType: p?.metadata?.type,
                    postSpeciesHash: p?.metadata?.speciesHash,
                    postSpeciesTitle: p?.metadata?.speciesTitle,
                    targetId: p.targetId,
                    nrLikes: p.reactionsCount,
                    nrComments: p.commentsCount,
                    wasLiked: p.myReactions?.includes(REACTION.LIKE) ?? false,
                };
                return post;
            }),
        );
        const payloadData = {
            groups: [],
            posts,
            nextPage,
        };
        return payloadData;
    },
);

export const getExplore = createAsyncThunk('community/getExplore', async () => {
    let recommendedFromCache: CacheItem = AppCache.getInstance().getFromCache(CACHE_KEYS.RECOMMENDED);
    let recommended: GroupsProps[];

    if(recommendedFromCache.valid){
        recommended = recommendedFromCache.data;
    } else {
        const recommendedList = await getRecommendedCommunities();
        recommended = await Promise.all(
            recommendedList.map(async c => {
                const avatarFile = await getFile(c.avatarFileId);
                const category = await getCategory(c.categoryIds[0]);
                const group: GroupsProps = {
                    id: c.communityId,
                    image: avatarFile?.fileUrl,
                    name: c.displayName,
                    members: c.membersCount,
                    description: c.description ?? '', // TODO fix screen not support undefined
                    category: category?.name,
                };
                return group;
            }),
        );
        AppCache.getInstance().addToCache(CACHE_KEYS.RECOMMENDED, [...recommended], CACHE_EXPIRATIONS.ONE_HOUR);
    }

    let trendingFromCache: CacheItem = AppCache.getInstance().getFromCache(CACHE_KEYS.TRENDING);
    let trending: GroupsProps[];

    if(trendingFromCache.valid){
        trending = trendingFromCache.data;
    } else {
        const trendingList = await getTopTrendingCommunities();
        let topCount = 1;
        trending = await Promise.all(
            trendingList.map(async c => {
                const avatarFile = await getFile(c.avatarFileId);
                const category = await getCategory(c.categoryIds[0]);
                const top = topCount++;
                const group: GroupsProps = {
                    id: c.communityId,
                    image: avatarFile?.fileUrl,
                    name: c.displayName,
                    members: c.membersCount,
                    top,
                    category: category?.name,
                };
                return group;
            }),
        );

        AppCache.getInstance().addToCache(CACHE_KEYS.TRENDING, [...trending], CACHE_EXPIRATIONS.ONE_HOUR);
    }

    const payloadData = {
        recommended: {
            loading: false,
            groups: recommended,
        },
        trending: {
            loading: false,
            groups: trending,
        },
        categories: {
            loading: false,
            groups: [],
        },
    };
    return payloadData;
});

export const setSelectedGroup = createAsyncThunk('community/setSelectedGroup', async (data: any) => {
    return data;
});

export const searchGroup = createAsyncThunk('community/searchGroup', async (searchTerm: string, {dispatch}) => {
    let result: GroupsProps[] = [];
    // dispatch(updateSearchGroup({ searchTerm }))
    if (searchTerm.length == 0) {
        result = [];
    } else {
        const {data: communities, nextPage: communityNextPage} = await queryCommunities({
            displayName: searchTerm,
            isDeleted: false,
        });
        result = await Promise.all(
            communities.map(async c => {
                const avatarFile = await getFile(c.avatarFileId);
                const group: GroupsProps = {
                    id: c.communityId,
                    image: avatarFile?.fileUrl,
                    name: c.displayName,
                    members: c.membersCount,
                };
                return group;
            }),
        );
    }
    const payloadData = {
        result,
        searchTerm,
    };
    return payloadData;
});

const createPost = async (data: CreatePostProps) => {
    let attachments: Parameters<typeof A.createPost>[0]['attachments'] = [];
    if (data.postImageFile) {
        const formData = new FormData();
        const file = {
            name: data.postImageName,
            type: data.postImageType,
            uri: data.postImagePath,
        };
        console.log({file, data})
// @ts-ignore
        formData.append('files', data.postImageFile);
        let res: any = null;

        try {
            res = await A.createImage(formData)
        } catch (e) {
        }

        const amityFile = res.data[0];
        attachments.push({fileId: amityFile.fileId, type: amityFile.type});
    }

    const metadata = {
        type: data.postType,
        speciesHash: data.postSpeciesHash,
        speciesTitle: data.postSpeciesTitle,
    };

    if (data.postOnMyWall) {
        return await A.createPost({
            targetType: 'user',
            targetId: A.getActiveClient().userId!,
            data: {text: data.postText},
            metadata,
            attachments,
        });
    } else {
        return await A.createPost({
            targetType: 'community',
            targetId: data.postTargetGroupId!,
            data: {text: data.postText},
            metadata,
            attachments,
        });
    }
};

export const createImagePost = createAsyncThunk('community/createImagePost', async (data: CreatePostProps) => {
    await createPost(data);
    return true;
});

export const createTextPost = createAsyncThunk('community/createTextPost', async (data: CreatePostProps) => {
    await createPost(data);
    return true;
});

export const communityGetPostById = createAsyncThunk(
    'community/communityGetPostById',
    async (data: { postId: string; skipRefresh?: boolean }) => {
        const postData = await getPost(data.postId);
        const postedUser = await getUser(postData.postedUserId);
        const postedUserAvatarFile = await getFile(postedUser?.avatarFileId);
        const childrenPost = await getPosts(postData.children);
        let postImage: string | undefined = undefined;
        let postImageFull: string | undefined = undefined;
        if (childrenPost.length > 0) {
            const childrenFile = await getFile((childrenPost[0].data as any).fileId);
            postImage = childrenFile?.fileUrl + '?size=medium';
            postImageFull = childrenFile?.fileUrl + '?size=full';
        }
        const {
            data: commentList,
            nextPage,
            prevPage,
        } = await queryComments({
            referenceId: data.postId,
            referenceType: 'post',
            isDeleted: false,
        });
        const comments = await Promise.all(
            commentList.map(async c => {
                const commentUser = await getUser(c.userId);
                const commentUserAvatarFile = await getFile(commentUser?.avatarFileId);

                const comment: commentProps = {
                    userProfile: {
                        id: commentUser?.userId,
                        avatarPath: commentUser?.avatarCustomUrl,
                        displayName: commentUser?.displayName,
                    },
                    commentId: c['_id'],
                    comment: (c.data as any).text,
                    datePublished: c.createdAt,
                    nrLikes: c.reactionsCount,
                    nrComments: c.childrenNumber,
                    targetId: postData.targetId,
                    wasLiked: c.myReactions?.includes(REACTION.LIKE) ?? false,
                };

                return comment;
            }),
        );

        const post: postProps = {
            userProfile: {
                id: postedUser?.userId,
                displayName: postedUser?.displayName,
                avatarPath: postedUser?.avatarCustomUrl,
            },
            postId: postData?.postId,
            datePublished: postData.createdAt,
            postText: (postData.data as any).text,
            postImage,
            postImageFull,
            postType: postData?.metadata?.type,
            postSpeciesHash: postData?.metadata?.speciesHash,
            postSpeciesTitle: postData?.metadata?.speciesTitle,
            nrLikes: postData.reactionsCount,
            nrComments: postData.commentsCount,
            targetType: postData.targetType,
            targetId: postData.targetId,
            wasLiked: postData.myReactions?.includes(REACTION.LIKE) ?? false,
            comments,
        };
        return {commentsPrevPage: prevPage, post};
    },
);

export const communityLoadPrevCommentsForPost = createAsyncThunk(
    'community/communityLoadPrevCommentsForPost',
    async (data: { postId: string; page: any }) => {
        const {data: commentList, prevPage} = await queryComments({
            referenceId: data.postId,
            referenceType: 'post',
            isDeleted: false,
            page: data.page,
        });
        const comments = await Promise.all(
            commentList.map(async c => {
                const commentUser = await getUser(c.userId);
                const commentUserAvatarFile = await getFile(commentUser?.avatarFileId);

                const comment: commentProps = {
                    userProfile: {
                        id: commentUser?.userId,
                        avatarPath: commentUser?.avatarCustomUrl,
                        displayName: commentUser?.displayName,
                    },
                    commentId: c['_id'],
                    comment: (c.data as any).text,
                    datePublished: c.createdAt,
                    nrLikes: c.reactionsCount,
                    nrComments: c.childrenNumber,
                    wasLiked: c.myReactions?.includes(REACTION.LIKE) ?? false,
                };

                return comment;
            }),
        );

        return {comments, prevPage};
    },
);

export const communityLikePostById = createAsyncThunk(
    'community/communityLikePostById',
    async (data: { postId: string; likedByMe: boolean }) => {
        if (data.likedByMe) {
            return await A.removeReaction('post', data.postId, 'like');
        } else {
            return await A.addReaction('post', data.postId, 'like');
        }
        // return await postData(postId);
    },
);

export const communityLikeCommentById = createAsyncThunk(
    'community/communityLikeCommentById',
    async (data: { commentId: string; likedByMe: boolean }) => {
        if (data.likedByMe) {
            return await A.removeReaction('comment', data.commentId, 'like');
        } else {
            return await A.addReaction('comment', data.commentId, 'like');
        }
    },
);

export const communityCommentOnPostById = createAsyncThunk(
    'community/communityCommentOnPostById',
    async (data: { comment: string; postId: string }) => {
        return await A.createComment({
            referenceType: 'post',
            referenceId: data.postId,
            data: {
                text: data.comment,
            },
        });
    },
);

export const communityFlagPostById = createAsyncThunk('community/communityFlagPostById', async (postId: string) => {
    return await reportPost(postId);
});

export const communityDeletePostById = createAsyncThunk('community/communityDeletePostById', async (postId: string) => {
    return await deletePost(postId);
});

export const communityFlagUserById = createAsyncThunk('community/communityFlagUserById', async (userId: string) => {
    return await reportUser(userId);
});

export const communityDeleteCommentById = createAsyncThunk(
    'community/communityDeleteCommentById',
    async (commentId: string) => {
        return await deleteComment(commentId);
    },
);

export const showCommunityReportLayerForComment = createAsyncThunk(
    'community/showCommunityReportLayerForComment',
    async (data: { commentId: string; ownerId: string }) => {
        return {};
    },
);

export const showCommunityReportLayerForPost = createAsyncThunk(
    'community/showCommunityReportLayerForPost',
    async (data: { postId: string; ownerId: string }) => {
        return {};
    },
);

export const showCommunityReportLayerForUser = createAsyncThunk(
    'community/showCommunityReportLayerForUser',
    async (data: { userId: string }) => {
        return {};
    },
);

export const hideCommunityReportLayer = createAsyncThunk('community/hideCommunityReportLayer', async () => {
    return {};
});

export const communityFlagCommentById = createAsyncThunk(
    'community/communityFlagCommentById',
    async (commentId: string) => {
        return await reportComment(commentId);
    },
);

export const replayComment = createAsyncThunk('community/replayComment', async (comment: commentProps) => {
    return comment;
});

export const loadShareSnap = createAsyncThunk('community/loadShareSnap', async (postId: number) => {
    const payloadData = {
        userProfile: {
            avatarPath: 'https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg',
            displayName: 'dave',
        },
        datePublished: '30 mins',
        postText: 'King of the highlands',
        date: 'Sep 23, 2021',
        postImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8yLw1obR4hX9b0eRynRtXgAqc0BrAghe23g&usqp=CAU',
    };
    return payloadData;
});

export const communityGetCategories = createAsyncThunk('community/communityGetCategories', async () => {
    return await postData(categoriesData);
});

export const communityGetGroupsInCategoryId = createAsyncThunk(
    'community/communityGetGroupsInCategoryId',
    async (communityId: number) => {
        return await postData(categoryGroups);
    },
);

export const joinGroup = createAsyncThunk('community/joinGroup', async (communityId?: string) => {
    AppCache.getInstance().removeFromCache(CACHE_KEYS.ALL_GROUPS);
    return await joinCommunity(communityId);
});

export const leaveGroup = createAsyncThunk('community/leaveGroup', async (communityId?: string) => {
    AppCache.getInstance().removeFromCache(CACHE_KEYS.ALL_GROUPS);
    return await leaveCommunity(communityId);
});

export const getAllCommunities = createAsyncThunk(
    'community/getAllCommunities',
    async (data?: { page?: { after?: number; limit: number } }) => {
        let allGroupsFromCache: CacheItem = AppCache.getInstance().getFromCache(CACHE_KEYS.ALL_GROUPS);
        let returnData = [];

        if(allGroupsFromCache.valid){
            returnData = allGroupsFromCache.data;
        } else {
            let groups = [];


            let page: Amity.Page<number> | undefined = undefined;

            let {data: myCommunities, nextPage} = await queryCommunities({
                isDeleted: false,
                membership: 'all',
                page: data?.page,
            });
            page = nextPage;

            let parsedGroups = await Promise.all(
                myCommunities.map(async c => {
                    const avatarFile = await getFile(c.avatarFileId);
                    const group: GroupsProps = {
                        id: c.communityId,
                        image: avatarFile?.fileUrl,
                        name: c.displayName,
                        isJoined: c.isJoined,
                        members: c.membersCount,
                    };
                    return group;
                }),
            );

            // @ts-ignore
            groups = [...groups, ...parsedGroups];

            while (page) {
                let {data: myCommunities, nextPage} = await queryCommunities({
                    isDeleted: false,
                    membership: 'all',
                    page: page,
                });
                page = nextPage;
                const parsedGroups = await Promise.all(
                    myCommunities.map(async c => {
                        const avatarFile = await getFile(c.avatarFileId);
                        const group: GroupsProps = {
                            id: c.communityId,
                            image: avatarFile?.fileUrl,
                            name: c.displayName,
                            isJoined: c.isJoined,
                            members: c.membersCount,
                        };
                        return group;
                    }),
                );
                // @ts-ignore
                groups = [...groups, ...parsedGroups];
            }

            returnData = groups;
            AppCache.getInstance().addToCache(CACHE_KEYS.ALL_GROUPS, [...returnData], CACHE_EXPIRATIONS.ONE_HOUR);
        }

        return returnData;
    },
);

export const getCommunityProfile = createAsyncThunk(
    'community/getCommunityProfile',
    async (data: { page?: any; forceRefreshPosts?: boolean }, redux) => {
        const state = (redux.getState() as any).application as ApplicationState;
        const userId = state.profile?.userId;
        if (userId) {
            const user = await getUser(userId);
            const myFeed = await queryPosts({targetType: 'user', targetId: userId, page: data?.page, isDeleted: false});
            const nextPage = myFeed.nextPage;

            const posts = await Promise.all(
                myFeed.data.map(async (p: Amity.Post) => {
                    const postedUser = await getUser(p.postedUserId);
                    const avatarFile = await getFile(postedUser?.avatarFileId);
                    const childrenPost = await getPosts(p.children);
                    let postImage: string | undefined = undefined;
                    let postImageFull: string | undefined = undefined;
                    if (childrenPost.length > 0) {
                        const childrenFile = await getFile((childrenPost[0].data as any).fileId);
                        postImage = childrenFile?.fileUrl + '?size=medium';
                        postImageFull = childrenFile?.fileUrl + '?size=full';
                    }

                    const post: postProps = {
                        postId: p.postId,
                        userProfile: {
                            id: postedUser?.userId,
                            displayName: postedUser?.displayName,
                            avatarPath: postedUser?.avatarCustomUrl,
                        },
                        datePublished: p.createdAt,
                        postText: (p.data as any).text,
                        postImage,
                        postImageFull,
                        postType: p?.metadata?.type,
                        postSpeciesHash: p?.metadata?.speciesHash,
                        postSpeciesTitle: p?.metadata?.speciesTitle,
                        nrComments: p.commentsCount,
                        nrLikes: p.reactionsCount,
                        wasLiked: p.myReactions?.includes(REACTION.LIKE) ?? false,
                    };
                    return post;
                }),
            );

            return {user, posts, nextPage};
        } else {
            return {};
        }
    },
);

export const getCommunityUser = createAsyncThunk('community/getCommunityUser', async (userId: string) => {
    if (userId) {
        const user = await getUser(userId);
        const myFeed = await queryPosts({targetType: 'user', targetId: userId, isDeleted: false});
        const nextPage = myFeed.nextPage;

        const posts = await Promise.all(
            myFeed.data.map(async (p: Amity.Post) => {
                if (p.dataType === "custom.share") {
                    p = await getPost(p.data['postId']);
                }

                const postedUser = await getUser(p.postedUserId);
                const avatarFile = await getFile(postedUser?.avatarFileId);
                const childrenPost = await getPosts(p.children);
                let postImage: string | undefined = undefined;
                let postImageFull: string | undefined = undefined;
                if (childrenPost.length > 0) {
                    const childrenFile = await getFile((childrenPost[0].data as any).fileId);
                    postImage = childrenFile?.fileUrl + '?size=medium';
                    postImageFull = childrenFile?.fileUrl + '?size=full';
                }

                const post: postProps = {
                    postId: p.postId,
                    userProfile: {
                        id: postedUser?.userId,
                        displayName: postedUser?.displayName,
                        avatarPath: postedUser?.avatarCustomUrl,
                    },
                    datePublished: p.createdAt,
                    postText: (p.data as any).text,
                    postImage,
                    postImageFull,
                    postType: p?.metadata?.type,
                    postSpeciesHash: p?.metadata?.speciesHash,
                    postSpeciesTitle: p?.metadata?.speciesTitle,
                    nrComments: p.commentsCount,
                    nrLikes: p.reactionsCount,
                    wasLiked: p.myReactions?.includes(REACTION.LIKE) ?? false,
                };
                return post;
            }),
        );

        return {user, posts, nextPage};
    } else {
        return {};
    }
});

export const getCommunityUserPosts = createAsyncThunk(
    'community/getCommunityUserPosts',
    async (data: { userId: string; page?: any; forceRefresh?: boolean }) => {
        if (data.userId) {
            const myFeed = await queryPosts({
                targetType: 'user',
                targetId: data.userId,
                page: data.page,
                isDeleted: false
            });
            const nextPage = myFeed.nextPage;

            const posts = await Promise.all(
                myFeed.data.map(async (p: Amity.Post) => {
                    if (p.dataType === "custom.share") {
                        p = await getPost(p.data['postId']);
                    }
                    const postedUser = await getUser(p.postedUserId);
                    const avatarFile = await getFile(postedUser?.avatarFileId);
                    const childrenPost = await getPosts(p.children);
                    let postImage: string | undefined = undefined;
                    let postImageFull: string | undefined = undefined;
                    if (childrenPost.length > 0) {
                        const childrenFile = await getFile((childrenPost[0].data as any).fileId);
                        postImage = childrenFile?.fileUrl + '?size=medium';
                        postImageFull = childrenFile?.fileUrl + '?size=full';
                    }

                    console.log(p)

                    const post: postProps = {
                        postId: p.postId,
                        userProfile: {
                            id: postedUser?.userId,
                            displayName: postedUser?.displayName,
                            avatarPath: postedUser?.avatarCustomUrl,
                        },
                        datePublished: p.createdAt,
                        postText: (p.data as any).text,
                        postImage,
                        postImageFull,
                        postType: p?.metadata?.type,
                        postSpeciesHash: p?.metadata?.speciesHash,
                        postSpeciesTitle: p?.metadata?.speciesTitle,
                        nrComments: p.commentsCount,
                        nrLikes: p.reactionsCount,
                        wasLiked: p.myReactions?.includes(REACTION.LIKE) ?? false,
                    };
                    return post;
                }),
            );

            return {posts, nextPage};
        } else {
            return {};
        }
    },
);

export const getCommunityUserFollowInfo = createAsyncThunk(
    'community/getCommunityUserFollowInfo',
    async (userId: string) => {
        if (userId) {
            const data = await getUserFollowInfo(userId);
            return data;
        } else {
            return {};
        }
    },
);

export const communityFollowUser = createAsyncThunk('community/followUser', async (userId: string) => {
    if (userId) {
        const data = await followUser(userId);
        return data;
    } else {
        return {};
    }
});

export const communityUnFollowUser = createAsyncThunk('community/communityUnFollowUser', async (userId: string) => {
    if (userId) {
        const data = await unFollowUser(userId);
        return data;
    } else {
        return {};
    }
});

export const setCommunityTarget = createAsyncThunk(
    'community/setCommunityTarget',
    async (communityTarget: CommunityTarget) => {
        return communityTarget;
    },
);

async function uploadImageToS3() {
    // upload image and return upload response
    return 'uploadResponse';
}

const postData = (data: any) =>
    new Promise(resolve => {
        setTimeout(function () {
            resolve(data);
        }, 1000);
    });
