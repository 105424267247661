import React, { useEffect, useState, useRef } from "react";
import "./PostScreen.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useParams } from "react-router-dom";
import {
    selectCurrentPostCommentsPrevPage,
    selectCurrentPostMoreCommentsLoading,
    selectPostDetail, selectPostDetailLoading
} from "../../app/slices/community/communitySelectors";
import {
    communityCommentOnPostById,
    communityGetPostById,
    communityLoadPrevCommentsForPost
} from "../../app/slices/community/communityActions";
import { commentProps, postProps } from "../../app/slices/community/communitySlice";
import Loader from "../../components/Loader/Loader";
import Post from "../../components/Post/Post";
import Comment from "../../components/Comment/Comment";
import CircularImage from "../../components/CircularImage/CircularImage";
import LoadMoreButton from "../../components/LoadMoreButton/LoadMoreButton";
import { selectUserProfileImage } from "../../app/slices/application/applicationSelectors";

export interface PostScreenProps {
}

const PostScreen = (props: PostScreenProps) => {
    const dispatch = useAppDispatch();
    const params = useParams();

    const [newComment, setNewComment] = useState<string>("");
    const newCommentRef = useRef<HTMLTextAreaElement>(null);

    const postId = params.id;

    const dataLoading: boolean = useAppSelector(selectPostDetailLoading);
    const post: postProps | null = useAppSelector(selectPostDetail);
    const commentsPrevPage = useAppSelector(selectCurrentPostCommentsPrevPage);
    const moreCommentsLoading = useAppSelector(selectCurrentPostMoreCommentsLoading);
    const myProfileImage = useAppSelector(selectUserProfileImage);


    const sendNewComment = async () => {
        await dispatch(communityCommentOnPostById({ comment: newComment, postId: post?.postId || "" }))
        await dispatch(communityGetPostById({ postId: post?.postId || "", skipRefresh: true }));
        setNewComment("");
    }

    useEffect(() => {
        dispatch(communityGetPostById({ postId: postId || "" }));
    }, []);

    const focusNewCommentInput = () => {
        newCommentRef.current!.focus()
    }

    if (dataLoading) {
        return (
            <Loader />
        )
    }

    return (
        <div className="PostScreen">
            <Post {...post!} inPostScreen focusNewCommentInput={focusNewCommentInput} />
            {commentsPrevPage && !moreCommentsLoading && (
                <LoadMoreButton
                    onClick={() =>
                        dispatch(
                            communityLoadPrevCommentsForPost({
                                postId: postId || "",
                                page: commentsPrevPage,
                            }),
                        )
                    }>
                    Load previous comments
                </LoadMoreButton>
            )}
            <div className="postscreen__comments">
                {
                    post?.comments?.map((comment: commentProps) => {
                        return (
                            <Comment comment={comment} />
                        )
                    })
                }

                <div className="comment__new-comment">
                    <CircularImage isProfileImage={true} src={myProfileImage} />
                    <textarea ref={newCommentRef} minLength={1} value={newComment} onChange={(e) => setNewComment(e.target.value)} />
                    <button onClick={sendNewComment}>Post</button>
                </div>
            </div>

        </div>
    )
}

export default PostScreen;
