import { Navigate } from "react-router-dom";
import {useAppSelector} from "../../app/hooks";
import {selectUserLoggedIn} from "../../app/slices/application/applicationSelectors";

const ProtectedRoute = (props: any) => {
    const isLoggedIn = useAppSelector(selectUserLoggedIn);
    return isLoggedIn ? props.children : <Navigate to="/login" />
}

export default ProtectedRoute;
