import CommunitySidebarElement, {Community} from "../CommunitySidebarElement/CommunitySidebarElement";
import "./CommunitySidebar.css";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectAllCommunities} from "../../app/slices/community/communitySelectors";

type ComunitySidebarProps = {
    communities: Community[]
}

const CommunitySidebar = (props: ComunitySidebarProps) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const allGroups = useSelector(selectAllCommunities);
    const joinedGroups = allGroups.filter(g => g.isJoined);

    let selectedCommunityId = params.id;
    if (location.pathname.indexOf('/group/') === -1) {
        selectedCommunityId = "";
    }

    return <div className="community-sidebar-container">
        <div className="community-sidebar-title">My communities</div>
        {joinedGroups.map(group => {
            return <div key={group.id} className="community-sidebar-element-container">
                <CommunitySidebarElement id={group.id} image={group.image || ''} name={group.name}
                                         onClick={() => navigate(`/group/${group.id}`)}
                                         selected={group.id === selectedCommunityId}/>
            </div>
        })}

        {joinedGroups.length === 0 &&
        <div className="community-sidebar__join-community">
            <button className="community-sidebar__join-community-button">Nothing here</button>
        </div>
        }

    </div>
}

export default CommunitySidebar;
