import { createSlice } from '@reduxjs/toolkit';
import {
  communityCommentOnPostById,
  communityDeleteCommentById,
  communityDeletePostById,
  communityFlagCommentById,
  communityFlagPostById, communityFlagUserById,
  communityGetCategories,
  communityGetGroupsInCategoryId,
  communityGetPostById,
  communityLikeCommentById,
  communityLikePostById, communityLoadPrevCommentsForPost,
  createImagePost,
  createTextPost,
  getAllCommunities,
  getCommunityProfile,
  getCommunityUser,
  getCommunityUserPosts,
  getExplore,
  getNewsFeed,
  hideCommunityReportLayer,
  joinGroup,
  leaveGroup,
  loadCommunityById,
  loadCommunityPosts,
  loadShareSnap,
  replayComment,
  searchGroup,
  setCommunityTarget,
  setSelectedGroup,
  showCommunityReportLayerForComment,
  showCommunityReportLayerForPost,
  showCommunityReportLayerForUser
} from './communityActions';
import {
  deleteCommentAndUpdateCommentCount,
  deletePostFromList,
  updatePostArrayCommentCount,
  updatePostArrayLikeCount,
  updatePostLikeCount,
} from './communityUtils';

export interface CommunityProps {
  communityId?: string;
  avatarFileId?: string;
  displayName?: string;
  description?: string;
  postsCount?: number;
  membersCount?: number;
  joined?: boolean;
  type?: string;
}

export interface PostsProps {
  postId: string;
  avatarFileId: string;
  displayName: string;
  description: string;
  commentsCount: number;
  likeCount: number;
  wasLiked: boolean;
  editedAt: string;
}

export interface GroupsProps {
  id: string;
  image?: string;
  name: string;
  category?: string;
  top?: number;
  members?: number;
  isJoined?: boolean;
  description?: string;
}

export interface commentProps {
  commentId: string;
  userProfile: {
    id?: string;
    avatarPath?: string;
    displayName?: string;
  };
  comment: string;
  datePublished: string;
  postText?: string;
  postImage?: string;
  targetId?: string;
  nrLikes: number;
  nrComments: number;
  wasLiked: boolean;
}

export interface postProps {
  postId: string;
  userProfile?: {
    id?: string;
    avatarPath?: string;
    displayName?: string;
  };
  datePublished?: string;
  postText?: string;
  postLink?: string;
  postImage?: string;
  postImageFull?: string;
  postType?: string;
  postSpeciesHash?: string;
  postSpeciesTitle?: string;
  nrLikes?: number;
  targetType?: string;
  targetId?: string;
  nrComments?: number;
  wasLiked?: boolean;
  date?: string;
  comments?: commentProps[];
}

interface RecommendedProps {
  loading: boolean;
  groups: GroupsProps[];
}

interface NewsPostsProps {
  loading: boolean;
  posts: postProps[];
  nextPage?: Amity.Page;
}

export interface CurrentGroupScreen {
  group: CommunityProps | null;
  posts: postProps[];
  loading: boolean;
  loadingPost: boolean;
  nextPage: { limit?: number; next?: string; before?: string; after?: string } | undefined;
}

interface ExploreScreen {
  recommended: RecommendedProps;
  trending: RecommendedProps;
  categories: RecommendedProps;
}

interface SearchProps {
  searchTerm: string;
  isLoading: boolean;
  result: GroupsProps[];
}

interface CategoriesProps {
  loading: boolean;
  result: GroupsProps[];
}

interface CategoryGroupProps {
  loading: boolean;
  result: GroupsProps[];
}

export interface CreatePostProps {
  isCreatingPost?: boolean;
  postImagePath?: string;
  postImageType?: string;
  postImageFile?: any;
  postImageName?: string;
  postText?: string;
  postType?: string;
  postSpeciesHash?: string;
  postSpeciesTitle?: string;
  postTargetGroupId?: string;
  postOnMyWall?: boolean;
}

export interface PostDetail {
  loading: boolean;
  post: postProps | null;
  commentsPrevPage: any;
  moreCommentsLoading: boolean;
}

export interface CommunityLikePost {
  loading: boolean;
}

export interface CommunityLikeComment {
  loading: boolean;
}

export interface CommunityCommentOnPostById {
  loading: boolean;
}

export interface ReplayComment {
  loading: boolean;
  comment: commentProps | null;
}

export interface CommunityTarget {
  communityId?: string;
  communityName?: string;
  communityImage?: string;
  onMyFeed?: boolean;
}

export interface NewsFeedScreen {
  myCommunitiesLoading: boolean;
  myCommunities: GroupsProps[];
  newsFeedLoading: boolean;
  newsFeed: postProps[];
  newsFeedNextPage: { limit?: number; after?: number } | undefined;
}

export interface AnotherUserScreen {
  user: any;
  userFeedLoading: boolean;
  userFeed: postProps[];
  userFeedNextPage: { limit?: number; after?: number } | undefined;
}

export interface ReportCommunityContent {
  postOwnerId: string;
  commentOwnerId: string;
  postId: string;
  commentId: string;
  userId: string;

  visible: boolean;
}

export interface CommunityState {
  newsFeedLoading: boolean;
  communityProfileLoading: boolean;

  allCommunities: GroupsProps[];
  currentGroupScreen: CurrentGroupScreen;
  exploreScreen: ExploreScreen;
  myCommunities: RecommendedProps;
  selectedGroup: GroupsProps | null;
  searchGroupData: SearchProps;
  categoriesData: CategoriesProps;
  categoryGroupData: CategoryGroupProps;
  newPost: CreatePostProps;
  postDetail: PostDetail;
  communityLikePost: CommunityLikePost;
  communityLikeComment: CommunityLikeComment;
  communityCommentOnPostById: CommunityCommentOnPostById;
  replayComment: ReplayComment;
  shareSnap: postProps | null;
  communityTarget: CommunityTarget;
  myCommunityProfile: MyCommunityProfile;
  newsFeedScreen: NewsFeedScreen;
  anotherUserScreen: AnotherUserScreen;
  reportCommunityContent: ReportCommunityContent;
}

export interface MyCommunityProfile {
  displayName?: string;
  createdAt?: string;
  posts?: postProps[];
  nextPage?: string;
}

const initialState: CommunityState = {
  newsFeedLoading: false,
  communityProfileLoading: false,

  allCommunities: [],
  currentGroupScreen: {
    loading: false,
    posts: [],
    group: null,
    loadingPost: false,
    nextPage: { limit: 10 },
  },
  exploreScreen: {
    recommended: {
      loading: false,
      groups: [],
    },
    trending: {
      loading: false,
      groups: [],
    },
    categories: {
      loading: false,
      groups: [],
    },
  },

  myCommunities: {
    loading: false,
    groups: [],
  },
  selectedGroup: null,

  searchGroupData: {
    searchTerm: '',
    isLoading: false,
    result: [],
  },
  categoriesData: {
    loading: false,
    result: [],
  },

  categoryGroupData: {
    loading: false,
    result: [],
  },

  newPost: {
    isCreatingPost: false,
    postImagePath: '',
    postText: '',
    postTargetGroupId: '0',
    postOnMyWall: false,
  },
  postDetail: {
    loading: false,
    post: null,
    commentsPrevPage: null,
    moreCommentsLoading: false
  },
  communityLikePost: {
    loading: false,
  },
  communityLikeComment: {
    loading: false,
  },
  communityCommentOnPostById: {
    loading: false,
  },
  replayComment: {
    loading: false,
    comment: null,
  },
  shareSnap: null,
  communityTarget: {},
  myCommunityProfile: {
    posts: [],
  },

  newsFeedScreen: {
    myCommunitiesLoading: true,
    myCommunities: [],
    newsFeedLoading: true,
    newsFeed: [],
    newsFeedNextPage: { limit: 10 },
  },
  anotherUserScreen: {
    user: null,
    userFeedLoading: false,
    userFeed: [],
    userFeedNextPage: { limit: 10 },
  },
  reportCommunityContent: {
    visible: false,
    postId: '',
    commentId: '',
    userId: '',
    postOwnerId: '',
    commentOwnerId: '',
  },
  
};

export const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    updateSearchGroup: (state, action) => {
      state.searchGroupData = { ...state.searchGroupData, ...action.payload };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadCommunityById.pending, state => {
        state.currentGroupScreen!.group = null;
        state.currentGroupScreen!.posts = [];
        state.currentGroupScreen!.loading = true;
      })
      .addCase(loadCommunityById.fulfilled, (state, action) => {
        state.currentGroupScreen!.group = action.payload;
        state.currentGroupScreen!.loading = false;
      })
      .addCase(loadCommunityById.rejected, state => {
        state.currentGroupScreen!.loading = false;
      })
      .addCase(loadCommunityPosts.pending, (state, action) => {
        state.currentGroupScreen!.loadingPost = true;
      })
      .addCase(loadCommunityPosts.fulfilled, (state, action) => {
        const forceRefresh = action.meta.arg.forceRefresh;

        if (state.currentGroupScreen!.posts) {
          // @ts-ignore
          state.currentGroupScreen!.posts = [...state.currentGroupScreen!.posts, ...action.payload.posts];
        } else {
          // @ts-ignore
          state.currentGroupScreen!.posts = action.payload.posts;
        }

        if(forceRefresh){
          // @ts-ignore
          state.currentGroupScreen!.posts = action.payload.posts;
        }

        state.currentGroupScreen.nextPage = action.payload.nextPage;
        state.currentGroupScreen.loadingPost = false;
      })
      .addCase(loadCommunityPosts.rejected, (state, action) => {
        state.currentGroupScreen!.loadingPost = false;
      })

      .addCase(getExplore.pending, (state, action) => {
        state.exploreScreen.recommended.loading = true;
        state.exploreScreen.trending.loading = true;
        state.exploreScreen.categories.loading = true;
      })
      .addCase(getExplore.fulfilled, (state, action) => {
        state.exploreScreen!.recommended.groups = action.payload.recommended.groups;
        state.exploreScreen!.trending.groups = action.payload.trending.groups;
        state.exploreScreen!.categories.groups = action.payload.categories.groups;
        state.exploreScreen.recommended.loading = false;
        state.exploreScreen.trending.loading = false;
        state.exploreScreen.categories.loading = false;
      })
      .addCase(getExplore.rejected, (state, action) => {
        state.exploreScreen.recommended.loading = false;
        state.exploreScreen.trending.loading = false;
        state.exploreScreen.categories.loading = false;
      })

      .addCase(setSelectedGroup.fulfilled, (state, action) => {
        state.selectedGroup = action.payload;
      })

      .addCase(searchGroup.pending, (state, action) => {
        state.searchGroupData!.isLoading = true;
      })
      .addCase(searchGroup.fulfilled, (state, action) => {
        state.searchGroupData!.isLoading = false;
        state.searchGroupData!.result = action.payload.result;
        state.searchGroupData!.searchTerm = action.payload.searchTerm;
      })
      .addCase(searchGroup.rejected, (state, action) => {
        state.searchGroupData!.isLoading = false;
      })

      .addCase(createImagePost.pending, (state, action) => {
        state.newPost!.isCreatingPost = true;
      })
      .addCase(createImagePost.fulfilled, (state, action) => {
        state.communityTarget = {};
        state.newPost!.isCreatingPost = false;
      })
      .addCase(createImagePost.rejected, (state, action) => {
        let message = action.error?.message;

        // @ts-ignore
        if (action.error?.message?.indexOf('Data contain blacklist word >>') > -1) {
          const forbiddenWord = action.error?.message?.split('Data contain blacklist word >>')[1];
          message = `You have used a forbidden word: ${forbiddenWord}`;
        } else { // @ts-ignore
          if (action.error?.message?.indexOf('Data contain link that is not in whitelist') > -1) {
                    message = 'You tried posting a hyperlink to a blocked website';
                  }
        }

       alert('Unable to create post');
        state.newPost!.isCreatingPost = false;
      })

      .addCase(createTextPost.pending, (state, action) => {
        state.newPost!.isCreatingPost = true;
      })
      .addCase(createTextPost.fulfilled, (state, action) => {
        state.communityTarget = {};
        state.newPost!.isCreatingPost = false;
      })
      .addCase(createTextPost.rejected, (state, action) => {
        let message = action.error?.message;
        // @ts-ignore
        if (action.error?.message?.indexOf('Data contain blacklist word >>') > -1) {
          const forbiddenWord = action.error?.message?.split('Data contain blacklist word >>')[1];
          message = `You have used a forbidden word: ${forbiddenWord}`;
        } else { // @ts-ignore
          if (action.error?.message?.indexOf('Data contain link that is not in whitelist') > -1) {
                    message = 'You tried posting a hyperlink to a blocked website';
                  }
        }

        alert('Unable to create post');

        state.newPost!.isCreatingPost = false;
      })
      .addCase(communityGetPostById.pending, (state, action) => {
        state.postDetail!.loading = true;

        if(!action.meta.arg.skipRefresh) {
          state.postDetail!.post = null;
          state.postDetail!.commentsPrevPage = null;
        }
      })
      .addCase(communityGetPostById.fulfilled, (state, action) => {
        state.postDetail!.loading = false;
        state.postDetail!.post = action.payload.post;
        state.postDetail!.commentsPrevPage = action.payload.commentsPrevPage;
      })
      .addCase(communityGetPostById.rejected, (state, action) => {
        state.postDetail!.loading = false;
      })


      .addCase(communityLoadPrevCommentsForPost.pending, (state, action) => {
        state.postDetail!.moreCommentsLoading = true;
      })
      .addCase(communityLoadPrevCommentsForPost.fulfilled, (state, action) => {
        state.postDetail!.moreCommentsLoading = false;
        // @ts-ignore
        state.postDetail!.post.comments = [...action.payload.comments, ...state.postDetail!.post.comments];
        state.postDetail!.commentsPrevPage = action.payload.prevPage;
      })
      .addCase(communityLoadPrevCommentsForPost.rejected, (state, action) => {
        state.postDetail!.moreCommentsLoading = false;
      })


      .addCase(communityLikePostById.fulfilled, (state, action) => {
        const likedByMe = action.meta.arg.likedByMe;
        const postId = action.meta.arg.postId;

        state.newsFeedScreen.newsFeed = updatePostArrayLikeCount(state.newsFeedScreen.newsFeed, postId, likedByMe);
        state.currentGroupScreen.posts = updatePostArrayLikeCount(state.currentGroupScreen.posts, postId, likedByMe);
        // @ts-ignore
        state.myCommunityProfile.posts = updatePostArrayLikeCount(state.myCommunityProfile.posts, postId, likedByMe);
        state.anotherUserScreen.userFeed = updatePostArrayLikeCount(
          state.anotherUserScreen.userFeed,
          postId,
          likedByMe,
        );
        state.postDetail.post = updatePostLikeCount(state.postDetail?.post, likedByMe);
      })
      .addCase(communityLikePostById.rejected, (state, action) => {
        state.communityLikePost!.loading = false;
      })

      .addCase(communityLikeCommentById.pending, (state, action) => {
        state.communityLikeComment!.loading = true;
      })
      .addCase(communityLikeCommentById.fulfilled, (state, action) => {
        const likedByMe = action.meta.arg.likedByMe;
        const commentId = action.meta.arg.commentId;
        // @ts-ignore
        let comment = state.postDetail?.post?.comments.find(comment => comment.commentId === commentId);
        if (comment) {
          if (likedByMe === true) {
            comment.nrLikes -= 1;
          } else {
            comment.nrLikes += 1;
          }
          comment.wasLiked = !likedByMe;
        }
        state.communityLikeComment!.loading = false;
      })
      .addCase(communityLikeCommentById.rejected, (state, action) => {
        state.communityLikeComment!.loading = false;
      })

      .addCase(communityCommentOnPostById.pending, (state, action) => {
        state.communityCommentOnPostById!.loading = true;
      })
      .addCase(communityCommentOnPostById.fulfilled, (state, action) => {
        state.communityCommentOnPostById!.loading = false;
        // @ts-ignore
        state.postDetail!.post?.comments.push(action.payload);
        state.newsFeedScreen.newsFeed = updatePostArrayCommentCount(
          state.newsFeedScreen.newsFeed,
          action.payload.data.referenceId,
        );
        state.anotherUserScreen.userFeed = updatePostArrayCommentCount(
          state.anotherUserScreen.userFeed,
          action.payload.data.referenceId,
        );
        // @ts-ignore
        state.myCommunityProfile.posts = updatePostArrayCommentCount(
          state.myCommunityProfile.posts || [],
          action.payload.data.referenceId,
        );
        state.currentGroupScreen.posts = updatePostArrayCommentCount(
          state.currentGroupScreen.posts,
          action.payload.data.referenceId,
        );
      })
      .addCase(communityCommentOnPostById.rejected, (state, action) => {
        let message = action.error?.message;

        // @ts-ignore
        if (action.error?.message?.indexOf('Data contain blacklist word >>') > -1) {
          const forbiddenWord = action.error?.message?.split('Data contain blacklist word >>')[1];
          message = `You have used a forbidden word: ${forbiddenWord}`;
        } else { // @ts-ignore
          if (action.error?.message?.indexOf('Data contain link that is not in whitelist') > -1) {
                    message = 'You tried posting a hyperlink to a blocked website';
                  }
        }

        alert('Unable to create comment');

        state.communityCommentOnPostById!.loading = false;
      })

      .addCase(replayComment.pending, (state, action) => {
        state.replayComment!.loading = true;
      })
      .addCase(replayComment.fulfilled, (state, action) => {
        state.replayComment!.loading = false;
        state.replayComment!.comment = action.payload;
      })
      .addCase(replayComment.rejected, (state, action) => {
        state.replayComment!.loading = false;
      })

      .addCase(communityGetCategories.pending, (state, action) => {
        state.categoriesData!.loading = true;
      })
      .addCase(communityGetCategories.fulfilled, (state, action) => {
        state.categoriesData!.loading = false;
        // @ts-ignore
        state.categoriesData.result = action.payload;
      })
      .addCase(communityGetCategories.rejected, (state, action) => {
        state.categoriesData!.loading = false;
      })
      .addCase(communityGetGroupsInCategoryId.pending, (state, action) => {
        state.categoryGroupData!.loading = true;
      })
      .addCase(communityGetGroupsInCategoryId.fulfilled, (state, action) => {
        state.categoryGroupData.loading = false;
        // @ts-ignore
        state.categoryGroupData!.result = action.payload;
      })
      .addCase(communityGetGroupsInCategoryId.rejected, (state, action) => {
        state.categoryGroupData!.loading = false;
      })
      .addCase(getAllCommunities.fulfilled, (state, action) => {
        state.allCommunities = action.payload;
      })
      .addCase(joinGroup.fulfilled, (state, action) => {
        const groupId = action.meta.arg;
        const groupFromAllGroups = state.allCommunities.find(c => c.id === groupId);
        if(groupFromAllGroups) {
          groupFromAllGroups.isJoined = true;
        }
        if(state.currentGroupScreen && state.currentGroupScreen.group){
          state.currentGroupScreen.group.joined = true;
          // @ts-ignore
          state.currentGroupScreen.group.membersCount += 1;
        }
      })
      .addCase(leaveGroup.fulfilled, (state, action) => {
        const groupId = action.meta.arg;
        const groupFromAllGroups = state.allCommunities.find(c => c.id === groupId);
        // @ts-ignore
        groupFromAllGroups.isJoined = false;
        // @ts-ignore
        state.currentGroupScreen.group.joined = false;
        // @ts-ignore
        state.currentGroupScreen.group.membersCount -= 1;
      })
      .addCase(setCommunityTarget.fulfilled, (state, action) => {
        state.communityTarget = action.payload;
      })
      .addCase(getCommunityProfile.fulfilled, (state, action) => {
        const { user, posts, nextPage } = action.payload;

        state.myCommunityProfile.displayName = user?.displayName;
        state.myCommunityProfile.createdAt = user?.createdAt;
        if (action.meta.arg?.forceRefreshPosts) {
          state.myCommunityProfile.posts = posts || [];
        } else {
          if (state.myCommunityProfile.posts?.length) {
            // @ts-ignore
            state.myCommunityProfile.posts = [...state.myCommunityProfile.posts, ...posts];
          } else {
            state.myCommunityProfile.posts = posts || [];
          }
        }

        // @ts-ignore
        state.myCommunityProfile.nextPage = nextPage;
      })
      .addCase(getNewsFeed.pending, (state, action) => {
        state.newsFeedScreen.newsFeedLoading = true;
        state.newsFeedScreen.newsFeedNextPage = undefined;
      })
      .addCase(getNewsFeed.fulfilled, (state, action) => {
        state.newsFeedScreen.myCommunitiesLoading = false;
        state.newsFeedScreen.newsFeedLoading = false;

        if (state.newsFeedScreen.newsFeed.length && action.meta.arg.refresh !== true) {
          state.newsFeedScreen.newsFeed = [...state.newsFeedScreen.newsFeed, ...action.payload.posts];
        } else {
          state.newsFeedScreen.newsFeed = action.payload.posts;
        }

        state.newsFeedScreen.newsFeedNextPage = action.payload.nextPage;
        state.newsFeedScreen.newsFeedLoading = false;
      })
      .addCase(getNewsFeed.rejected, (state, action) => {
        state.newsFeedScreen.newsFeedLoading = false;
      })
      .addCase(getCommunityUser.pending, (state, action) => {
        state.anotherUserScreen.userFeedLoading = true;

        if (action.meta.arg !== state.anotherUserScreen.user?.id) {
          state.anotherUserScreen.user = {};
          state.anotherUserScreen.userFeed = [];
        }
      })
      .addCase(getCommunityUser.fulfilled, (state, action) => {
        state.anotherUserScreen.userFeedLoading = false;
        state.anotherUserScreen.user = action.payload.user;
        state.anotherUserScreen.userFeedLoading = false;

        if (state.anotherUserScreen.userFeed.length) {
          state.anotherUserScreen.userFeed = [...state.anotherUserScreen.userFeed, ...action.payload.posts || []];
        } else {
          // @ts-ignore
          state.anotherUserScreen.userFeed = action.payload.posts;
        }

        // @ts-ignore
        state.anotherUserScreen.userFeedNextPage = action.payload.nextPage;
      })
      .addCase(getCommunityUserPosts.fulfilled, (state, action) => {
        const refresh = action.meta.arg.forceRefresh;

        if (state.anotherUserScreen.userFeed.length) {
          // @ts-ignore
          state.anotherUserScreen.userFeed = [...state.anotherUserScreen.userFeed, ...action.payload.posts];
        } else {
          // @ts-ignore
          state.anotherUserScreen.userFeed = action.payload.posts;
        }

        if(refresh){
          // @ts-ignore
          state.anotherUserScreen.userFeed = action.payload.posts;
        }
        // @ts-ignore
        state.anotherUserScreen.userFeedNextPage = action.payload.nextPage;
      })
      .addCase(hideCommunityReportLayer.fulfilled, (state, action) => {
        state.reportCommunityContent.visible = false;

        state.reportCommunityContent.commentId = '';
        state.reportCommunityContent.postId = '';
        state.reportCommunityContent.userId = '';

        state.reportCommunityContent.commentOwnerId = '';
        state.reportCommunityContent.postOwnerId = '';
      })
      .addCase(showCommunityReportLayerForComment.fulfilled, (state, action) => {
        state.reportCommunityContent.commentId = action.meta.arg.commentId;
        state.reportCommunityContent.commentOwnerId = action.meta.arg.ownerId;
        state.reportCommunityContent.visible = true;
      })
      .addCase(showCommunityReportLayerForPost.fulfilled, (state, action) => {
        state.reportCommunityContent.postId = action.meta.arg.postId;
        state.reportCommunityContent.postOwnerId = action.meta.arg.ownerId;
        state.reportCommunityContent.visible = true;
      })
      .addCase(showCommunityReportLayerForUser.fulfilled, (state, action) => {
        state.reportCommunityContent.userId = action.meta.arg.userId;
        state.reportCommunityContent.visible = true;
      })
      .addCase(communityDeleteCommentById.pending, (state, action) => {
        state.reportCommunityContent.visible = false;
      })
      .addCase(communityDeleteCommentById.fulfilled, (state, action) => {
        state.reportCommunityContent.visible = false;
        state.reportCommunityContent.commentId = '';
        state.reportCommunityContent.postId = '';
        state.reportCommunityContent.userId = '';

        state.reportCommunityContent.commentOwnerId = '';
        state.reportCommunityContent.postOwnerId = '';

        // @ts-ignore
        const deletedCommentId = action?.payload?._id;
        if (deletedCommentId) {
          const parentPostId = action.payload.referenceId;

          state.newsFeedScreen.newsFeed = deleteCommentAndUpdateCommentCount(
            state.newsFeedScreen.newsFeed,
            deletedCommentId,
            parentPostId,
          );
          state.anotherUserScreen.userFeed = deleteCommentAndUpdateCommentCount(
            state.anotherUserScreen.userFeed,
            deletedCommentId,
            parentPostId,
          );
          // @ts-ignore
          state.myCommunityProfile.posts = deleteCommentAndUpdateCommentCount(
            state.myCommunityProfile.posts || [],
            deletedCommentId,
            parentPostId,
          );
          state.currentGroupScreen.posts = deleteCommentAndUpdateCommentCount(
            state.currentGroupScreen.posts,
            deletedCommentId,
            parentPostId,
          );
          state.postDetail.post = deleteCommentAndUpdateCommentCount(
            [state.postDetail.post],
            deletedCommentId,
            parentPostId,
          )[0];
        }
      })
      .addCase(communityDeletePostById.pending, (state, action) => {
        state.reportCommunityContent.visible = false;
      })
      .addCase(communityFlagPostById.pending, (state, action) => {
        state.reportCommunityContent.visible = false;
      })
      .addCase(communityFlagPostById.fulfilled, (state, action) => {
        state.reportCommunityContent.visible = false;
        state.reportCommunityContent.commentId = '';
        state.reportCommunityContent.postId = '';
        state.reportCommunityContent.userId = '';

        state.reportCommunityContent.commentOwnerId = '';
        state.reportCommunityContent.postOwnerId = '';
      })
      .addCase(communityFlagPostById.rejected, (state, action) => {
        state.reportCommunityContent.visible = false;
        state.reportCommunityContent.commentId = '';
        state.reportCommunityContent.postId = '';
        state.reportCommunityContent.userId = '';

        state.reportCommunityContent.commentOwnerId = '';
        state.reportCommunityContent.postOwnerId = '';
      })
      .addCase(communityFlagUserById.pending, (state, action) => {
        state.reportCommunityContent.visible = false;
      })
      .addCase(communityFlagUserById.fulfilled, (state, action) => {
        state.reportCommunityContent.visible = false;
        state.reportCommunityContent.commentId = '';
        state.reportCommunityContent.postId = '';
        state.reportCommunityContent.userId = '';

        state.reportCommunityContent.commentOwnerId = '';
        state.reportCommunityContent.postOwnerId = '';
      })
      .addCase(communityFlagUserById.rejected, (state, action) => {
        state.reportCommunityContent.visible = false;
        state.reportCommunityContent.commentId = '';
        state.reportCommunityContent.postId = '';
        state.reportCommunityContent.userId = '';

        state.reportCommunityContent.commentOwnerId = '';
        state.reportCommunityContent.postOwnerId = '';
      })
      .addCase(communityFlagCommentById.pending, (state, action) => {
        state.reportCommunityContent.visible = false;
      })
      .addCase(communityFlagCommentById.fulfilled, (state, action) => {
        state.reportCommunityContent.visible = false;
        state.reportCommunityContent.commentId = '';
        state.reportCommunityContent.postId = '';
        state.reportCommunityContent.userId = '';

        state.reportCommunityContent.commentOwnerId = '';
        state.reportCommunityContent.postOwnerId = '';
      })
      .addCase(communityFlagCommentById.rejected, (state, action) => {
        state.reportCommunityContent.visible = false;
        state.reportCommunityContent.commentId = '';
        state.reportCommunityContent.postId = '';
        state.reportCommunityContent.userId = '';

        state.reportCommunityContent.commentOwnerId = '';
        state.reportCommunityContent.postOwnerId = '';
      })
      .addCase(communityDeletePostById.fulfilled, (state, action) => {
        state.reportCommunityContent.visible = false;
        state.reportCommunityContent.commentId = '';
        state.reportCommunityContent.postId = '';
        state.reportCommunityContent.userId = '';

        state.reportCommunityContent.commentOwnerId = '';
        state.reportCommunityContent.postOwnerId = '';

        const deletedPostId = action.payload.postId;
        if (deletedPostId) {
          state.newsFeedScreen.newsFeed = deletePostFromList(state.newsFeedScreen.newsFeed, deletedPostId);
          state.anotherUserScreen.userFeed = deletePostFromList(state.anotherUserScreen.userFeed, deletedPostId);
          // @ts-ignore
          state.myCommunityProfile.posts = deletePostFromList(state.myCommunityProfile.posts, deletedPostId);
          state.currentGroupScreen.posts = deletePostFromList(state.currentGroupScreen.posts, deletedPostId);
        }
      })
      .addCase(communityDeletePostById.rejected, (state, action) => {
        state.reportCommunityContent.visible = false;
        state.reportCommunityContent.commentId = '';
        state.reportCommunityContent.postId = '';
        state.reportCommunityContent.userId = '';

        state.reportCommunityContent.commentOwnerId = '';
        state.reportCommunityContent.postOwnerId = '';
      })
      .addCase(communityDeleteCommentById.rejected, (state, action) => {
        state.reportCommunityContent.visible = false;
        state.reportCommunityContent.commentId = '';
        state.reportCommunityContent.postId = '';
        state.reportCommunityContent.userId = '';

        state.reportCommunityContent.commentOwnerId = '';
        state.reportCommunityContent.postOwnerId = '';
      });
  },
});

export const { updateSearchGroup } = communitySlice.actions;
export default communitySlice.reducer;
