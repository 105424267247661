import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginScreen.css';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { authenticateWithEmail, authenticateWithSocial } from "../../app/slices/application/applicationActions";
import { selectAuthWithEmailErrorMessage, selectUserLoggedIn } from "../../app/slices/application/applicationSelectors";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import RecoverPasswordScreen from "../RecoverPasswordScreen/RecoverPasswordScreen";
import AppleIcon from "../../assets/apple-icon.svg";
import GoogleIcon from "../../assets/google-icon.svg";
import FacebookIcon from "../../assets/facebook-icon.svg";
import RegisterScreen from '../RegisterScreen/RegisterScreen';

function LoginScreen() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showLoader, setShowLoader] = useState<boolean>(true);

    const [recover, setRecover] = useState<boolean>(false);
    const [register, setRegister] = useState<boolean>(false);

    const loginErrorMessage = useAppSelector(selectAuthWithEmailErrorMessage);
    const userLoggedIn = useSelector(selectUserLoggedIn)

    useEffect(() => {
        setTimeout(() => {
            setShowLoader(false);
        }, 3000)
    }, [])

    if (userLoggedIn) {
        navigate('/newsfeed');
    }

    if (recover) {
        return (
            <RecoverPasswordScreen />
        )
    }

    if (register) {
        return (
            <RegisterScreen />
        )
    }
    
    const handleSubmit = event => {
        event.preventDefault();
        dispatch(authenticateWithEmail({ email: email, password: password }))
    }

    const onLoginWithGoogleClicked = () => {
        dispatch(authenticateWithSocial("GOOGLE"))
    }

    const onLoginWithFacebookClicked = () => {
        dispatch(authenticateWithSocial("FACEBOOK"))
    }

    const onLoginWithAppleClicked = () => {
        dispatch(authenticateWithSocial("APPLE"))
    }

    if (showLoader) {
        return (
            <Loader />
        )
    }

    return (
        <div className="LoginScreen">
            <form onSubmit={handleSubmit}>
                <div className="login-form-container">
                    <div className='login-header'>Sign In</div>
                    {loginErrorMessage && <div className='login-error-container'>{loginErrorMessage}</div>}
                    <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email..." />
                    <input value={password} type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password..." />
                    <div className="login__utils-buttons">
                        <button onClick={() => setRegister(true)} type='button' className='register-button'>Register</button>
                        <button onClick={() => setRecover(true)} type='button' className='forgot-password-button'>Forgot password?</button>
                    </div>
                    <button type='submit' className='login-button login-with-email'>Login</button>
                    <button className='login-button login-with-google' onClick={onLoginWithGoogleClicked}><img src={GoogleIcon}/>Login with Google</button>
                    <button className='login-button login-with-facebook' onClick={onLoginWithFacebookClicked}><img src={FacebookIcon}/>Login with facebook</button>
                    <button className='login-button login-with-apple' onClick={onLoginWithAppleClicked}><img src={AppleIcon}/>Login with Apple</button>
                </div></form>

        </div>
    );
}

export default LoginScreen;
