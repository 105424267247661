import React, {useEffect} from "react";
import "./NewsfeedScreen.css";
import {useSelector} from "react-redux";
import {
    selectNewsFeedScreenNewsFeedLoading, selectNewsFeedScreenNewsFeedNextPage,
    selectNewsFeedScreenNewsFeedPosts
} from "../../app/slices/community/communitySelectors";
import Loader from "../../components/Loader/Loader";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {getNewsFeed} from "../../app/slices/community/communityActions";
import Post from "../../components/Post/Post";
import LoadMoreButton from "../../components/LoadMoreButton/LoadMoreButton";

export interface NewsfeedScreenProps {
}

export const NewsfeedScreen = (props: NewsfeedScreenProps) => {
    const dispatch = useAppDispatch();
    const newsFeedPosts = useAppSelector(selectNewsFeedScreenNewsFeedPosts);
    const newsFeedPostsLoading = useAppSelector(selectNewsFeedScreenNewsFeedLoading);
    const newsFeedNextPage = useAppSelector(selectNewsFeedScreenNewsFeedNextPage);

    useEffect(() => {
        loadPosts(true);
    }, []);

    const loadPosts = (refresh?: boolean) => {
        dispatch(getNewsFeed({ page: newsFeedNextPage, refresh: refresh }));
    };

    const onLoadMoreVisible = data => {
        if (data) {
            loadPosts();
        }
    };


    return (
        <div className="NewsfeedScreen">
            {newsFeedPosts.map(post=>{
                return (
                    <Post {...post}/>
                )
            })}

            {newsFeedNextPage && <LoadMoreButton onClick={onLoadMoreVisible}>Load more</LoadMoreButton>}

            {newsFeedPostsLoading &&
            <Loader/>
            }
        </div>
    )
}

