import React from 'react';

export const PATTERNS = {
    url: /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.(xn--)?[a-z0-9-]{2,20}\b([-a-zA-Z0-9@:%_\+\[\],.~#?&\/=]*[-a-zA-Z0-9@:%_\+\]~#?&\/=])*/i,
    hashtag: /[#]+[A-Za-z0-9-_]+/g,
};

interface ParsedText{
    className: string;
    value: string
}

const ParsedText = (props: any) => {
    if(!props.value){
        return null;
    }

    let parsed = props.value.replace(PATTERNS.url, (t=>{
       return `<a href=${t} target="_blank">${t}</a>`
    }));

    parsed = parsed.replace(PATTERNS.hashtag, (t=>{
        return `<a style=color:#9e9e9e;>${t}</a>`
    }));

    return (
        <div className={props.className} dangerouslySetInnerHTML={{__html: parsed}} />
    )
}

export default ParsedText;
