import "./InnerLoader.css";

const InnerLoader = () => {
    return <div className="InnerLoader">
        <div className="lds-roller-inner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
}

export default InnerLoader;
