import icon from "../../assets/new-post-icon.svg";
import "./NewPostButton.css";

export interface NewPostButtonProps {
    onClick: () => void;
}

const NewPostButton = (props: NewPostButtonProps) => {
    return <div className="new-post-button__container" onClick={props.onClick}>
        <img src={icon} alt="new-post-button-icon"/>
    </div>
}

export default NewPostButton
