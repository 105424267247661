import { Country, UserMonetizationStatus, UserRole } from './user.model';

export class UserProfileDTO {
  userId: string;
  email: string;
  role: UserRole;
  displayName?: string;
  monetizationStatus?: UserMonetizationStatus;
  subscriptionExpires?: string;
  country?: Country;
  snapsAvailable: number;
  snapsLastRefresh?: Date;
  socialToken: string;
  profileImage?: string;
  sign_in_provider?: string;

  constructor(data: UserProfileDTO) {
    Object.assign(this, data);
  }
}

export class CountryDTO {
  name: string;
  code: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class CountriesDTO {
  countries: CountryDTO[];

  constructor(data: any) {
    Object.assign(this, data);
    this.countries = data?.countries.map((item: any) => new CountryDTO(item));
  }
}

export class UpdateCountryDTO {
  code: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class NotificationTokenDTO {
  token: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
