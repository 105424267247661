import CircularImage from "../CircularImage/CircularImage";
import "./TrendingCard.css";
import {GroupsProps} from "../../app/slices/community/communitySlice";
import { membersCountFormatter } from "../../utils";

type TrendingCardProps = {
    data: GroupsProps;
    index?: number;
    onClick: (groupId: string)=>void;
}

const TrendingCard = (props: TrendingCardProps) => {
    return <div className="trending-card__container" onClick={()=>props.onClick(props.data.id)}>
        <CircularImage isProfileImage={false} src={props.data.image || ""} />
        <div className="trending-card__index">{props.index}</div>
        <div className="trending-card__details">
            <div className="trending-card__name">{props.data.name}</div>
            <div className="trending-card__secondary-details">
                <div className="trending-card__category">{props.data.category}</div>
                <div className="trending-card__details-bullet" />
                <div className="trending-card__members">{membersCountFormatter(props.data.members)}</div>
            </div>
        </div>
    </div>
}

export default TrendingCard;
