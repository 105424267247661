import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectDeleteModalData, selectReportModalData } from "../../app/slices/application/applicationSelectors";
import { showDeleteModalAction } from "../../app/slices/application/applicationSlice";
import { communityDeleteCommentById, communityDeletePostById, communityFlagCommentById, communityFlagPostById, communityFlagUserById } from "../../app/slices/community/communityActions";
import "./DeleteModal.css";

const DeleteModal = () => {
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectDeleteModalData);

    const hideDeleteModal = () => {
        dispatch(showDeleteModalAction(undefined));
    };

    const onDeleteItemClicked = () => {
        if (data?.targetType === "comment") {
            dispatch(communityDeleteCommentById(data.targetId))
        }
        if (data?.targetType === "post") {
            dispatch(communityDeletePostById(data.targetId))
        }
        hideDeleteModal();
    }

    return <div className="delete-modal">
        <div className="delete-modal__content">
            <div className="delete-modal__text">Delete {data?.targetType}?</div>
            <div className="delete-modal__under">You are about to delete a {data?.targetType}. Are you sure you want to proceed?</div>
            <div className="delete-modal__footer">
                <button className="delete-modal__cancel-button" onClick={hideDeleteModal}>Cancel</button>
                <button className="delete-modal__confirm-button" onClick={onDeleteItemClicked}>Delete</button>
            </div>
        </div>
    </div>
}

export default DeleteModal;
