import CircularImage from "../CircularImage/CircularImage";
import "./RecommendedCard.css";
import { GroupsProps } from "../../app/slices/community/communitySlice";
import { membersCountFormatter } from "../../utils"

type RecommendedCardProps = {
    data: GroupsProps;
    onClick: (groupId: string) => void;
}

const RecommendedCard = (props: RecommendedCardProps) => {
    return <div className="recommended-card__container" onClick={() => props.onClick(props.data.id)}>
        <CircularImage isProfileImage={false} src={props.data.image || ""} />
        <div className="recommended-card__name">{props.data.name}</div>
        <div className="recommended-card__members">{membersCountFormatter(props.data.members)}</div>
        <div className="recommended-card__description">{props.data.description}</div>
    </div>
}

export default RecommendedCard;
