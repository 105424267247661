import "./LoadMoreButton.css";

interface LoadMoreButtonProps {
    onClick: (data: any) => void;
    children: string;
}

const LoadMoreButton = (props: LoadMoreButtonProps) => {
    return <div className="load-more-button__container">
        <button className="load-more-button" onClick={props.onClick}>{props.children}</button>
    </div>
};

export default LoadMoreButton;