import React, { useState } from "react";
import "./UserScreen.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    selectAnotherUserScreenNewsFeedLoading,
    selectAnotherUserScreenNewsFeedNextPage,
    selectAnotherUserScreenNewsFeedPosts,
    selectAnotherUserScreenNewsFeedUser
} from "../../app/slices/community/communitySelectors";
import {
    communityFollowUser,
    communityUnFollowUser,
    getCommunityUser,
    getCommunityUserFollowInfo,
    getCommunityUserPosts
} from "../../app/slices/community/communityActions";
import { useNavigate, useParams } from "react-router-dom";
import InnerLoader from "../../components/InnerLoader/InnerLoader";
import CircularImage from "../../components/CircularImage/CircularImage";
import noPostsIcon from "../../assets/newspaper-icon.svg";
import Post from "../../components/Post/Post";
import TimeDistance from "../../components/TimeDistance/TimeDistance";
import LoadMoreButton from "../../components/LoadMoreButton/LoadMoreButton";
import Flag from 'react-world-flags';
import { followersCountFormatter } from "../../utils";
import EllypsisDropdownMenu from "../../components/EllypsisDropdownMenu/EllypsisDropdownMenu";
import Loader from "../../components/Loader/Loader";

export const UserScreen = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const [followersCount, setFollowersCount] = useState(0);
    const [following, setFollowing] = useState(false);

    const user = useAppSelector(selectAnotherUserScreenNewsFeedUser);
    const posts = useAppSelector(selectAnotherUserScreenNewsFeedPosts);
    const nextPage = useAppSelector(selectAnotherUserScreenNewsFeedNextPage);
    const postsLoading = useAppSelector(selectAnotherUserScreenNewsFeedLoading);

    const countryFlag = user?.metadata?.countryFlag;

    const userId = params.id || '';

    React.useEffect(() => {
        dispatch(getCommunityUser(userId));

        dispatch(getCommunityUserFollowInfo(userId))
            .unwrap()
            .then((data: any) => {
                setFollowersCount(data.followerCount);
                setFollowing(data?.status === 'accepted');
            });

    }, []);


    let postsToRender = posts;
    let displayNameToRender = user?.displayName;

    const onFollowUserClicked = () => {
        if (following) {
            dispatch(communityUnFollowUser(userId))
                .unwrap()
                .then(data => {
                    if (data === true) {
                        setFollowing(false);
                        setFollowersCount(followersCount - 1);
                    }
                });
        } else {
            dispatch(communityFollowUser(userId))
                .unwrap()
                .then((data: any) => {
                    setFollowing(data?.status === 'accepted');
                    if (data?.status === 'accepted') {
                        setFollowersCount(followersCount + 1);
                    }
                });
        }
    };

    const loadPosts = () => {
        if (nextPage) {
            dispatch(getCommunityUserPosts({ userId: userId || "", page: nextPage }))
        }
    }

    const onLoadMoreVisible = data => {
        if (data) {
            loadPosts();
        }
    };

    if (postsLoading) {
        return <Loader />
    }

    return (
        <div className="UserScreen">
            <div className="user-screen__header">
                <div className="user__user-details">
                    <CircularImage isProfileImage={true} src={user?.avatarCustomUrl || ""} />
                    <div className="user__user-details__right">
                        <div className="user__user-name">{displayNameToRender}</div>
                        <div className="user__joined-ago">
                            <TimeDistance date={user?.createdAt} prefix="Joined" />
                        </div>
                    </div>
                </div>
                <EllypsisDropdownMenu targetId={userId} targetType={"user"} isReportable isDeletable={false} />
            </div>
            <div className="user__followers">{followersCountFormatter(followersCount)}</div>
            {countryFlag && <div className="user__country"><Flag code={countryFlag} /></div>}
            <div className="follow-button__container">
                <button className="follow-button" onClick={onFollowUserClicked}>{following ? "Stop following" : "Follow"}</button>
            </div>

            <div className="profile-screen__timeline-label">Timeline</div>
            {!postsToRender && <div className="user__no-posts">
                <img src={noPostsIcon} />
                <div className="user__no-posts__text">No posts</div>
            </div>}
            <div className="user-screen__posts-section"> {postsToRender?.map(post => {
                return (
                    <Post {...post} />
                )
            })}</div>

            {postsLoading &&
                <InnerLoader />
            }

            {nextPage &&
                <LoadMoreButton onClick={onLoadMoreVisible}>Load More</LoadMoreButton>
            }
        </div>
    )
}
