export enum CACHE_KEYS {
    RECOMMENDED,
    TRENDING,
    ALL_GROUPS,
    NEWS_FEED,
}

export enum CACHE_EXPIRATIONS {
    ONE_MINUTE = 60 * 1000,
    FIVE_MINUTES = 5 * 60 * 1000,
    FIFTEEN_MINUTES = 15 * 60 * 1000,
    HALF_HOUR = 30 * 60 * 1000,
    ONE_HOUR = 60 * 60 * 1000,
    NEVER = 50 * 60 * 60 * 1000,
}

interface InternalCacheItem {
    key: CACHE_KEYS;
    expiration_time: number;
    data: any;
}

export interface CacheItem {
    key: CACHE_KEYS;
    valid: boolean;
    data: any;
}

export class AppCache{
    private static instance: AppCache;
    private cache:{};

    constructor(){
        const now = new Date().getTime();

        this.cache = this.getCacheFromDisk();

        for(let key in this.cache){
            let item: InternalCacheItem = this.cache[key];
            if(item.expiration_time < now){
                console.log("[CACHE EVICTOR]",item.expiration_time, now)
                delete this.cache[key];
            }
        }

        this.writeCacheToDisk();
    }

    public getFromCache(key: CACHE_KEYS): CacheItem {
        let now = new Date().getTime();
        let cacheItem: CacheItem = {key: key, valid: false, data: null};

        let item: InternalCacheItem = this.cache[key];
        if (item) {
            cacheItem.data = item.data;
            if (now < item.expiration_time) {
                console.log(`[CACHE HIT] ${key}`)
                cacheItem.valid = true;
            } else {
                console.log(`[CACHE MISS] ${key}`)
                delete this.cache[key];
                this.writeCacheToDisk();
            }
        } else {
            console.log(`[CACHE MISS] ${key}`)
        }

        return cacheItem;
    }

    public addToCache(key: CACHE_KEYS, data: any, expiration: CACHE_EXPIRATIONS) {
        let now = new Date().getTime();
        console.log(`[CACHE ADD] ${key}`)
        if (this.cache[key]) {
            let item: InternalCacheItem = this.cache[key];
            item.data = JSON.parse(JSON.stringify(data));
            item.expiration_time = now + expiration
        } else {
            let cacheItem: InternalCacheItem = {
                key: key,
                data: JSON.parse(JSON.stringify(data)),
                expiration_time: now + expiration
            }
            this.cache[key] = cacheItem;
        }

        this.writeCacheToDisk();
    }

    public removeFromCache(key:CACHE_KEYS){
        delete this.cache[key];
        this.writeCacheToDisk();
    }

    private getCacheFromDisk(){
        let data = localStorage.getItem("ES-COMM-CACHE");
        if(data){
            return JSON.parse(data);
        } else {
            return {};
        }
    }

    private writeCacheToDisk(){
        localStorage.setItem("ES-COMM-CACHE", JSON.stringify(this.cache));
    }

    public static getInstance(): AppCache {
        if (!AppCache.instance) {
            AppCache.instance = new AppCache();
        }

        return AppCache.instance;
    }
}

