import {RootState} from "../../store";

export const selectCurrentGroup = (state: RootState) => state.community.currentGroupScreen;

export const selectRecomended = (state: RootState) => state.community.exploreScreen.recommended;
export const selectTrending = (state: RootState) => state.community.exploreScreen.trending;
export const selectCommunityConnected = (state: RootState) => state.application.communityConnected;

export const selectSelectedGroup = (state: RootState) => state.community.selectedGroup;

export const selectSearchGroupData = (state: RootState) => state.community.searchGroupData;

export const selectAllCategoreisData = (state: RootState) => state.community.categoriesData;
export const selectCategoryGroupData = (state: RootState) => state.community.categoryGroupData;


export const isCreatingPost = (state: RootState) => state.community.newPost.isCreatingPost;

export const selectPostDetail = (state: RootState) => state.community.postDetail.post;
export const selectPostDetailLoading = (state: RootState) => state.community.postDetail.loading;
export const selectCurrentPostCommentsPrevPage = (state: RootState) => state.community.postDetail.commentsPrevPage;
export const selectCurrentPostMoreCommentsLoading = (state: RootState) => state.community.postDetail.moreCommentsLoading;
export const selectPostDetailImage = (state: RootState) => state.community.postDetail.post?.postImage;

export const communityLikePost = (state: RootState) => state.community.communityLikePost;

export const communityLikeComment = (state: RootState) => state.community.communityLikeComment;

export const communityCommentOnPostById = (state: RootState) => state.community.communityCommentOnPostById;

export const replayComment = (state: RootState) => state.community.replayComment;

export const selectShareSnapData = (state: RootState) => state.community.shareSnap;

export const selectCommunitiesIJoined = (state: RootState) => state.community.allCommunities.filter(c=>c.isJoined === true);
export const selectAllCommunities = (state: RootState) => state.community.allCommunities;

export const selectCommunityTarget = (state: RootState) => state.community.communityTarget;

export const selectMyFeed = (state: RootState) => state.community.myCommunityProfile.posts;
export const selectMyFeedNextPage = (state: RootState) => state.community.myCommunityProfile.nextPage;
export const selectMyDisplayName = (state: RootState) => state.community.myCommunityProfile.displayName;
export const selectMyJoinDate = (state: RootState) => state.community.myCommunityProfile.createdAt;


// news feed selectors
export const selectNewsFeedScreenCommunitiesLoading = (state: RootState) => state.community.newsFeedScreen.myCommunitiesLoading;
export const selectNewsFeedScreenJoinedCommunities = (state: RootState) => state.community.newsFeedScreen.myCommunities;
export const selectNewsFeedScreenNewsFeedLoading = (state: RootState) => state.community.newsFeedScreen.newsFeedLoading;
export const selectNewsFeedScreenNewsFeedPosts = (state: RootState) => state.community.newsFeedScreen.newsFeed;
export const selectNewsFeedScreenNewsFeedNextPage = (state: RootState) => state.community.newsFeedScreen.newsFeedNextPage;

// another user screen selectors
export const selectAnotherUserScreenNewsFeedLoading = (state: RootState) => state.community.anotherUserScreen.userFeedLoading;
export const selectAnotherUserScreenNewsFeedPosts = (state: RootState) => state.community.anotherUserScreen.userFeed;
export const selectAnotherUserScreenNewsFeedNextPage = (state: RootState) => state.community.anotherUserScreen.userFeedNextPage;
export const selectAnotherUserScreenNewsFeedUser = (state: RootState) => state.community.anotherUserScreen.user;

// group page selectors
export const selectGroupScreenIsLoadingPosts = (state: RootState) => state.community.currentGroupScreen.loadingPost;
export const selectGroupScreenPosts = (state: RootState) => state.community.currentGroupScreen.posts;
export const selectGroupScreenData = (state: RootState) => state.community.currentGroupScreen.group;
export const selectGroupScreenIsLoadingData = (state: RootState) => state.community.currentGroupScreen.loading;
export const selectGroupScreenNextPage = (state: RootState) => state.community.currentGroupScreen.nextPage;


// community report layer
export const selectIsCommunityReportLayerVisible = (state: RootState) => state.community.reportCommunityContent.visible;
export const selectCommunityReportLayerCommentId = (state: RootState) => state.community.reportCommunityContent.commentId;
export const selectCommunityReportLayerPostId = (state: RootState) => state.community.reportCommunityContent.postId;
export const selectCommunityReportLayerUserId = (state: RootState) => state.community.reportCommunityContent.userId;
export const selectCommunityReportLayerCommentOwnerId = (state: RootState) => state.community.reportCommunityContent.commentOwnerId;
export const selectCommunityReportLayerPostOwnerId = (state: RootState) => state.community.reportCommunityContent.postOwnerId;

export const selectIsNewsFeedRefreshing = (state: RootState) => state.community.newsFeedLoading;
export const selectIsCommunityProfileLoading = (state: RootState) => state.community.communityProfileLoading;
