import "./ChildPaddedLayout.css";
import React, {ReactElement} from "react";

export interface ChildPaddedLayoutProps {
    children?: ReactElement;
}

const ChildPaddedLayout = (props: ChildPaddedLayoutProps) => {
    return (
        <div className="ChildPaddedLayout">
            <div className="ChildPaddedLayoutContent">
                {props.children}
            </div>
        </div>
    )
}

export default ChildPaddedLayout;
