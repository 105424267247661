import "./TimeDistance.css"
import formatDistance from "date-fns/esm/formatDistance"
import subDays from "date-fns/esm/subDays"

export interface TimeDistanceProps {
    date: string | undefined;
    prefix? :string;
}

const TimeDistance = (props: TimeDistanceProps) => {
    let timeDistance = "";
    if(props.date) {
        timeDistance = formatDistance(new Date(props.date), new Date(), {addSuffix: true})
    }
    return <div className="time-distance">{`${props.prefix || ""} ${timeDistance}`}<span></span></div>
} 

export default TimeDistance