export const updatePostArrayCommentCount = (dataSource: any[], postId: string) => {
  let post = dataSource.find(post => post.postId === postId);
  if (post && dataSource?.length) {
    post.nrComments += 1;
  }

  return dataSource;
};

export const deleteCommentAndUpdateCommentCount = (dataSource: any[], commentId: string, postId: string) => {
  let post = dataSource.find(post => post.postId === postId);
  if (post && dataSource?.length) {
    post.nrComments -= 1;
    post.comments = post.comments?.filter(c=>c.commentId !== commentId);
  }

  return dataSource;
};

export const deletePostFromList = (dataSource: any[], postId: string) => {
  const remainingPosts = dataSource.filter(p=>p.postId !== postId);

  return remainingPosts;
};

export const updatePostArrayLikeCount = (dataSource: any[], postId: string, like: boolean) => {
  let post = dataSource.find(post => post.postId === postId);
  if (post && dataSource?.length) {
    if (like) {
      post.nrLikes -= 1;
    } else {
      post.nrLikes += 1;
    }
    post.wasLiked = !like;
  }

  return dataSource;
};

export const updatePostLikeCount = (post: any, like: boolean) => {
  if (post) {
    if (like) {
      post.nrLikes -= 1;
    } else {
      post.nrLikes += 1;
    }
    post.wasLiked = !like;
  }

  return post;
};
