import { Route, Routes, useNavigate } from 'react-router-dom';

import { useState, useEffect } from 'react';
import './App.css';
import { handleAppInit } from "./app/slices/application/applicationActions";
import { useAppDispatch } from "./app/hooks";
import { useSelector } from "react-redux";
import {
    selectAppInit,
    selectApplicationState, selectCommunityConnected,
    selectUserAuthFlowComplete,
    selectUserLoggedIn
} from "./app/slices/application/applicationSelectors";
import LoginScreen from "./screens/Login/LoginScreen";
import Layout from "./components/Layout/Layout";
import ExploreScreen from "./screens/Explore/ExploreScreen";
import ChildPaddedLayout from "./components/ChildPaddedLayout/ChildPaddedLayout";
import NewPostScreen from "./screens/NewPostScreen/NewPostScreen";
import PostScreen from "./screens/PostScreen/PostScreen";
import { GroupScreen } from "./screens/GroupScreen/GroupScreen";
import { NewsfeedScreen } from "./screens/Newsfeed/NewsfeedScreen";
import { MyProfileScreen } from "./screens/MyProfileScreen/MyProfileScreen";
import { UserScreen } from "./screens/UserScreen/UserScreen";
import { getAuth, getRedirectResult } from "firebase/auth";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import RecoverPasswordScreen from "./screens/RecoverPasswordScreen/RecoverPasswordScreen";
import MobileDownloadScreen from './screens/MobileDownloadScreen/MobileDownloadScreen';
import {UserEditScreen} from "./screens/UserEditScreen/UserEditScreen";

function App() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const userLoggedIn = useSelector(selectUserLoggedIn)
    const appInit = useSelector(selectAppInit)
    const communityConnected = useSelector(selectCommunityConnected)

    const [os, setOs] = useState<"" | "android" | "ios">("");

    const getUserOS = () => {
        const platform = window.navigator.userAgent.toLowerCase();
        if (platform.search("android") !== -1) {
            return "android"
        } else if (platform.search("iphone") !== -1 || platform.search("ipad") !== -1) {
            return "ios"
        } else return "";
    };

    useEffect(() => { setOs(getUserOS()) }, []);

    useEffect(() => {
        const init = async () => {
            await dispatch(handleAppInit());
        }

        init().finally();

    }, []);


    if (os) {
        return (
            <MobileDownloadScreen os={os} />
        )
    }

    if (appInit && !userLoggedIn) {
        return (
            <Layout showSidebar={false} showNavigation={false} showLoading={false}>
                <LoginScreen />
            </Layout>
        )
    }

    if (!communityConnected) {
        return (
            <Layout showSidebar={false} showNavigation={false} showLoading={true}>
                <div></div>
            </Layout>
        )
    }

    if (!appInit) {
        return (
            <Layout showSidebar={false} showNavigation={false} showLoading={true}>
                <div></div>
            </Layout>
        )
    }

    // @ts-ignore
    return (
        <div className="App">
            <Routes>
                <Route path="/login" element={
                    <Layout showSidebar={false} showNavigation={false}>
                        <LoginScreen />
                    </Layout>} />
                <Route path="/explore" element={
                    <Layout showSidebar={true} showNavigation={true}>
                        <ProtectedRoute>
                            <div><ExploreScreen /></div>
                        </ProtectedRoute>
                    </Layout>
                } />
                <Route path="/group/:id" element={
                    <Layout showSidebar={true} showNavigation={true}>
                        <ProtectedRoute>
                            <ChildPaddedLayout>
                                <div><GroupScreen /></div>
                            </ChildPaddedLayout>
                        </ProtectedRoute>
                    </Layout>
                } />
                <Route path="/post/:id" element={
                    <Layout showSidebar={true} showNavigation={true}>
                        <ProtectedRoute>
                            <ChildPaddedLayout>
                                <div><PostScreen /></div>
                            </ChildPaddedLayout>
                        </ProtectedRoute>
                    </Layout>
                } />
                <Route path="/user/:id" element={
                    <Layout showSidebar={true} showNavigation={true}>
                        <ProtectedRoute>
                            <ChildPaddedLayout>
                                <div><UserScreen /></div>
                            </ChildPaddedLayout>
                        </ProtectedRoute>
                    </Layout>
                } />
                <Route path="/new-post" element={
                    <Layout showSidebar={true} showNavigation={true}>
                        <ProtectedRoute>
                            <ChildPaddedLayout>
                                <div><NewPostScreen /></div>
                            </ChildPaddedLayout>
                        </ProtectedRoute>
                    </Layout>
                } />
                <Route path="/my-profile" element={
                    <Layout showSidebar={true} showNavigation={true}>
                        <ProtectedRoute>
                            <ChildPaddedLayout>
                                <div><MyProfileScreen /></div>
                            </ChildPaddedLayout>
                        </ProtectedRoute>
                    </Layout>
                } />
                <Route path="/edit-my-profile" element={
                    <Layout showSidebar={true} showNavigation={true}>
                        <ProtectedRoute>
                            <ChildPaddedLayout>
                                <div><UserEditScreen /></div>
                            </ChildPaddedLayout>
                        </ProtectedRoute>
                    </Layout>
                } />
                <Route path="/*" element={
                    <Layout showSidebar={true} showNavigation={true}>
                        <ProtectedRoute>
                            <ChildPaddedLayout>
                                <div><NewsfeedScreen /></div>
                            </ChildPaddedLayout>
                        </ProtectedRoute>
                    </Layout>
                } />
            </Routes>
        </div>
    );
}

export default App;
