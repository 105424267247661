import http from './http.service';
import { AxiosResponse } from 'axios';

class _ApiService {
  private user!: any;

  public getProfile<T>() {
    return this.get<T>('/users/me');
  }

  public getUserCollections<T>() {
    return this.get<T>('/collections/get');
  }

  public getSpeciesByHash<T>(hash: string, kind: string) {
    return this.get<T>(`/species/hash/${kind}/${hash}`);
  }

  public searchSpecies<T, G>(data: T) {
    return this.post<T, G>(`/species/search`, data);
  }

  public getSpeciesByName<T, G>(data: T) {
    return this.post<T, G>(`/species/name`, data);
  }

  public resolveSpeciesBulk<T>(kind: string, ids: string) {
    return this.get<T>(`/species/resolve/bulk?kind=${kind}&ids=${ids}`);
  }

  public getTopSnaps<T>() {
    return this.get<T>(`/snaps/top`);
  }

  public getAllCountries<T>() {
    return this.get<T>(`/users/countries`);
  }

  public getConfigurationData<T>() {
    return this.get<T>(`/config/data`);
  }

  public checkApiVersion<T, G>(data: T) {
    return this.post<T, G>(`/config/version`, data);
  }

  public deleteUserAccount<T>() {
    return this.delete<T>(`/users/delete`);
  }

  public getUserSnaps<T>() {
    return this.get<T>('/snaps/get');
  }

  public updateUserProfilePicture<T, G>(form: any) {
    return this.post<T, G>('/users/profile-image', form);
  }

  public updateUserDisplayName<T, G>(data: any) {
    return this.post<T, G>('/users/display-name', data);
  }

  public deleteSnap<T, G>(deleteSnapDTO: T) {
    return this.post<T, G>('/collections/snap/delete', deleteSnapDTO);
  }

  public createCollection<T, G>(data: T) {
    return this.post<T, G>('/collections/create', data);
  }

  public deleteCollection<T, G>(data: T) {
    return this.post<T, G>('/collections/delete', data);
  }

  public updateUserCountry<T, G>(data: T) {
    return this.post<T, G>('/users/country', data);
  }

  public addSnapToCollection<T, G>(data: T) {
    return this.post<T, G>('/collections/add-snaps-to-collection', data);
  }

  public removeSnapFromCollection<T, G>(data: T) {
    return this.post<T, G>('/collections/remove-snap-from-collection', data);
  }

  public createSnap<T, G>(data: T) {
    return this.post<T, G>('/snaps/create', data);
  }

  public suggestSnap<T, G>(data: T) {
    return this.post<T, G>('/snaps/suggest', data);
  }

  public getSnapsForMapLocation<T, G>(data: T) {
    return this.post<T, G>('/snaps/map', data);
  }

  public addNotificationToken<T, G>(data: T) {
    return this.post<T, G>('/users/notification-token', data);
  }

  public speciesElasticSearch<T, G>(data: T) {
    return this.post<T, G>('/els/smart_search', data);
  }

  public queryMapPins<T, G>(data: T) {
    return this.post<T, G>('/els/map_pins', data);
  }

  public getCollectionById<T>(collectionId: string) {
    return this.get<T>(`/collections/${collectionId}`);
  }

  public updateCollection<T, G>(data: T) {
    return this.patch<T, G>('/collections/update', data);
  }

  public imageIdentifyStart<T, G>(data: T) {
    return this.post<T, G>('/identify/start', data);
  }

  public imageIdentifyProcess<T, G>(data: T) {
    return this.post<T, G>('/identify/process', data);
  }

  public imageIdentifyPoll<T, G>(data: T) {
    return this.post<T, G>('/identify/poll', data);
  }

  public imageIdentifyComplete<T, G>(data: T) {
    return this.post<T, G>('/identify/complete', data);
  }

  public setUser(_user: any) {
    this.user = _user;
  }

  public verifyIOSReceipt<T, G>(data: T) {
    return this.post<T, G>('/payment/apple/verifyReceipt', data);
  }

  public verifyAndroidReceipt<T, G>(data: T) {
    return this.post<T, G>('/payment/google/verifyReceipt', data);
  }

  private async get<T>(path: string) {
    const token = await this.getToken();
    return http.get<T>(path, { headers: { Authorization: token } });
  }

  private async post<T, G>(path: string, data: T) {
    const token = await this.getToken();
    return http.post<T, AxiosResponse<G>>(path, data, {
      headers: { Authorization: token },
    });
  }

  private async patch<T, G>(path: string, data?: T) {
    const token = await this.getToken();
    return http.patch<T, AxiosResponse<G>>(path, data, {
      headers: { Authorization: token },
    });
  }

  private async delete<T>(path: string) {
    const token = await this.getToken();
    return http.delete<T>(path, { headers: { Authorization: token } });
  }

  private getToken = async (): Promise<string> => {
    const token = await this.user?.getIdToken();
    return token || '';
  };
}

export const ApiService = new _ApiService();
