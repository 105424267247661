import {createSlice} from '@reduxjs/toolkit';
import {
    authenticateWithEmail, changeUserDisplayName, changeUserProfilePicture,
    fetchUserProfile,
    handleAppInit,
    initialiseRequirements,
    postLoginDataFetch,
    postLogoutDataWipe,
    sendResetPasswordEmail,
    updateFirebaseCredentials,
} from './applicationActions';
import {UserProfileDTO} from "../../earthid/api/users/users.dto";
import firebase from "firebase/compat/app";
import {ApplicationStateModel} from "./applicationStateModel";
import {ConfigPathsDTO} from "../../earthid/api/config/config.dto";
import User = firebase.User;

export interface AppPermission {
    //determines if we can show the alert that asks the user for this permission
    canBeRequested: boolean;

    //determines if we must take the user to the settings screen
    shouldOpenSettingsScreen: boolean;

    hasPermission: boolean;
}

export type AppPermissions = {
    camera: AppPermission;
    gallery: AppPermission;
    location: AppPermission;
    // notification: AppPermission;
};

export interface ApplicationState {
    authErrorMessage: string;
    init: boolean;
    auth: User | null;
    profile: UserProfileDTO | null;
    authFlowComplete: boolean;
    errorScreen: null | 'NoConnection' | 'UnhandledError' | 'AuthError' | 'VersionMismatch';
    errorScreenTitle: string;
    errorMessage: string;
    firebaseToken: string;
    status: 'idle' | 'loading' | 'failed';
    resetPasswordMessage: string;
    hideMailResetButton: boolean;
    profileImage: string;
    showLoadingIndicator: boolean;
    loadingTarget: string | false;

    applicationState: ApplicationStateModel;
    userLoggedIn: boolean;
    communityConnected: boolean;
    configData: ConfigPathsDTO;
    reportModalData?: {
        targetId: string,
        targetType: "post" | "comment" | "user" | null
    };
    deleteModalData?: {
        targetId: string,
        targetType: "post" | "comment" | null
    };
}

const initialState: ApplicationState = {
    authErrorMessage: '',
    init: false,
    auth: null,
    profile: null,
    authFlowComplete: false,
    errorScreenTitle: '',
    errorScreen: null,
    errorMessage: '',
    firebaseToken: '',
    status: 'idle',
    resetPasswordMessage: '',
    hideMailResetButton: false,
    profileImage: '',
    showLoadingIndicator: false,
    loadingTarget: false,

    applicationState: ApplicationStateModel.Booting,
    userLoggedIn: false,
    communityConnected: false,
    configData: {
        AMT_TOKEN: '',
        AMT_REGION: '',
        MAX_DAILY_SNAPS: 5,
        API_VERSION: '',
    },
    reportModalData: undefined,
    deleteModalData: undefined
};

export const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        completeAuthFlow: (state, action) => {
            state.authFlowComplete = true;
            state.auth = action.payload.auth;
        },
        updateUserAuthStatus: (state, action) => {
            state.auth = action.payload.user;
        },
        updateFirebaseToken: (state, action) => {
            state.firebaseToken = action.payload.firebaseToken;
        },
        showReportModalAction2: (state, action) => {
            state.reportModalData = action.payload;
        },
        showDeleteModalAction: (state, action) => {
            state.deleteModalData = action.payload
        }

    },
    extraReducers: builder => {
        builder
            .addCase(handleAppInit.pending, state => {
                state.init = false;
                state.applicationState = ApplicationStateModel.Booting;
            })
            .addCase(handleAppInit.fulfilled, (state, action) => {
                state.applicationState = action.payload.applicationState;
                state.init = true;
            })
            .addCase(handleAppInit.rejected, (state, action) => {
                switch (action.error.message) {
                    case 'Network request failed':
                        state.errorScreen = 'NoConnection';
                        break;
                    case 'VersionMismatch':
                        state.errorScreen = 'VersionMismatch';
                        break;
                    default:
                        state.errorScreen = 'UnhandledError';
                        break;
                }
                state.applicationState = ApplicationStateModel.Critical;
                state.init = true;
            })
            .addCase(initialiseRequirements.fulfilled, (state, action) => {
                state.configData = action.payload.configData;
            })
            .addCase(initialiseRequirements.rejected, state => {
                state.applicationState = ApplicationStateModel.Critical;
            })
            .addCase(postLoginDataFetch.pending, (state, action) => {
                state.userLoggedIn = true;
            })
            .addCase(postLoginDataFetch.fulfilled, (state, action) => {
                state.communityConnected = action.payload.communityConnected;
                state.firebaseToken = action.payload.firebaseToken || '';
                // @ts-ignore
                state.auth = action.payload.firebaseAuth;
                state.applicationState = ApplicationStateModel.Ready;
            })

            .addCase(postLogoutDataWipe.fulfilled, state => {
                state.communityConnected = false;
                state.firebaseToken = '';
                state.auth = null;
                state.userLoggedIn = false;
                state.applicationState = ApplicationStateModel.MustLogin;
                state.profile = null;
            })

            .addCase(updateFirebaseCredentials.fulfilled, (state, action) => {
                // @ts-ignore
                state.auth = action.payload.firebaseAuth;
                state.userLoggedIn = true;
                state.firebaseToken = action.payload.firebaseToken || '';
            })

            .addCase(fetchUserProfile.fulfilled, (state, action) => {
                state.profile = action.payload;
                state.authFlowComplete = true;
            })
            .addCase(fetchUserProfile.rejected, state => {
                state.authFlowComplete = true;
            })
            .addCase(authenticateWithEmail.pending, state => {
                state.loadingTarget = 'EMAIL';
                state.authErrorMessage = '';
            })
            .addCase(authenticateWithEmail.fulfilled, state => {
                state.loadingTarget = false;
                state.userLoggedIn = true;
            })
            .addCase(authenticateWithEmail.rejected, (state, action) => {
                state.authErrorMessage = "Please verify your credentials and try again";

                state.loadingTarget = false;
            })

            .addCase(sendResetPasswordEmail.pending, state => {
                state.hideMailResetButton = true;
                state.resetPasswordMessage = "resetPasswordInProgress";
            })
            .addCase(sendResetPasswordEmail.fulfilled, state => {
                state.hideMailResetButton = true;
                state.resetPasswordMessage = "resetPasswordSuccess";
                state.authErrorMessage = "resetPasswordSuccess";
            })
            .addCase(sendResetPasswordEmail.rejected, state => {
                state.hideMailResetButton = true;
                state.resetPasswordMessage = "resetPasswordFailed";
            })
            .addCase(changeUserProfilePicture.pending, state => {
                state.showLoadingIndicator = true;
            })
            .addCase(changeUserProfilePicture.fulfilled, state => {
                state.showLoadingIndicator = false;
            })
            .addCase(changeUserProfilePicture.rejected, state => {
                state.showLoadingIndicator = false;
            })
            .addCase(changeUserDisplayName.pending, state => {
                state.showLoadingIndicator = true;
            })
            .addCase(changeUserDisplayName.fulfilled, state => {
                state.showLoadingIndicator = false;
            })
            .addCase(changeUserDisplayName.rejected, state => {
                state.showLoadingIndicator = false;
            })
    },
});

export const {
    updateUserAuthStatus,
    completeAuthFlow,
    updateFirebaseToken,
    showReportModalAction2,
    showDeleteModalAction
    // updateNotificationsPermissions,
} = applicationSlice.actions;

export default applicationSlice.reducer;
