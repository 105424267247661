import React, {ReactElement, useEffect} from "react";
import "./ExploreScreen.css";
import RecommendedCard from "../../components/RecommendedCard/RecommendedCard";
import TrendingCard from "../../components/TrendingCard/TrendingCard";
import CommunitySidebarElement from "../../components/CommunitySidebarElement/CommunitySidebarElement";
import {useSelector} from "react-redux";
import {selectAllCommunities, selectRecomended, selectTrending} from "../../app/slices/community/communitySelectors";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";
import {getExplore} from "../../app/slices/community/communityActions";
import InnerLoader from "../../components/InnerLoader/InnerLoader";

export interface ExploreScreenProps {
    children?: ReactElement;
}

const ExploreScreen = (props: ExploreScreenProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const recommended = useSelector(selectRecomended);
    const trending = useSelector(selectTrending);
    const allGroups = useSelector(selectAllCommunities);

    useEffect(()=>{
        dispatch(getExplore());
    }, [])

    return (
        <div className="ExploreScreen">
            <div className="ExploreScreenRecommended">
                <div className="ExploreScreenTitle ExploreScreenTitle__recommended">Recommended for you</div>
                <div className="ExploreScreenRecommendedWrapper">
                    {recommended.loading &&
                        <InnerLoader/>
                    }

                    {recommended.groups.map(group => {
                        return (
                            <div className="ExploreScreenRecommendedCard">
                                <RecommendedCard data={group} onClick={(groupId) => navigate(`/group/${groupId}`)}/>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="ExploreScreenTrending">
                <div className="ExploreScreenTrendingPadding">
                    <div className="ExploreScreenTitle">Today's Trending</div>

                    <div className="ExploreScreenTrendingWrapper">
                        {trending.loading &&
                        <InnerLoader/>
                        }

                        {trending.groups.map((group, index) => {
                            return (
                                <div className="ExploreScreenTrendingCard">
                                    <TrendingCard data={group} index={index + 1}
                                                  onClick={(groupId) => navigate(`/group/${groupId}`)}/>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>

            <div className="ExploreScreenCategories">
                <div className="ExploreScreenCategoriesPadding">
                    <div className="ExploreScreenTitle">Groups</div>

                    <div className="ExploreScreenCategoriesWrapper">

                        {allGroups.map((group, index) => {
                            return (
                                <div className="ExploreScreenCategoriesCard">
                                    <CommunitySidebarElement onClick={() => {
                                        navigate(`/group/${group.id}`)
                                    }} id={group.id} image={group.image || ''} name={group.name}
                                                             selected={false}/>
                                </div>

                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExploreScreen;
