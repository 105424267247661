import "./UserEditScreen.css";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    selectShowLoadingIndicator,
    selectUserDisplayName,
    selectUserProfileImage
} from "../../app/slices/application/applicationSelectors";
import React, {useState} from "react";
import {changeUserDisplayName, changeUserProfilePicture} from "../../app/slices/application/applicationActions";
import Loader from "../../components/Loader/Loader";

export const UserEditScreen = () => {
    const dispatch = useAppDispatch();

    const myProfileImage = useAppSelector(selectUserProfileImage);
    const myDisplayName = useAppSelector(selectUserDisplayName);
    const loading = useAppSelector(selectShowLoadingIndicator);

    const inputField = React.useRef() as React.MutableRefObject<HTMLInputElement>;


    const [image, setImage] = useState<any>(`https://data.earthsnap.com/${myProfileImage}`);
    const [displayName, setDisplayName] = useState<string>(myDisplayName);
    const [imageDirty, setImageDirty] = useState<boolean>(false);
    const [displayNameChanged, setDisplayNameChanged] = useState<boolean>(false);
    const [displayNameChangedError, setDisplayNameChangedError] = useState<boolean>(false);


    const onFileSelected = async (e) => {
        const file = e.target.files[0];
        const data = await readFile(file);
        const uri = URL.createObjectURL(file);
        // setUri(uri);
        // setFile(file);
        setImage(data);
        setImageDirty(true);
        // setImageType(file.type);
        // setImageName(file.name);
    }

    const readFile = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            const url = reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                return resolve(reader.result);
            }
        })
    }


    const onSaveImageClicked = () => {
        dispatch(changeUserProfilePicture(image.split('base64,')[1]));
        setImageDirty(false);
    }

    const onDisplayNameChanged = (e) =>{
        setDisplayName(e.target.value);
    }

    const onChangeDisplayNameClicked = async ()=>{
        if(!displayName.length){
            return;
        }
        const result = await dispatch(changeUserDisplayName(displayName));
        if(result['error']){
            setDisplayNameChanged(false);
            setDisplayNameChangedError(true);
        } else {
            setDisplayNameChanged(true);
            setDisplayNameChangedError(false);
        }
    }

    if(loading){
        return <Loader/>
    }

    return (
        <div>
            <h3>Your profile image</h3>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div className="UserEditScreen_profilePic-img-wrapper">
                    <img className="UserEditScreen_profilePic-img" src={image}/>
                </div>
                <div className="new-post__create-post-button__container">
                    <div style={{width: '300px'}}>
                        {imageDirty &&
                            <button className="new-post__create-post-button" style={{backgroundColor:'#4caf50'}} onClick={onSaveImageClicked}>Save selected image</button>
                        }
                        <button className="new-post__create-post-button" onClick={() => inputField.current.click()}>Select another image</button>
                    </div>
                    <input  accept="image/*" type='file' id='file' ref={inputField} style={{ display: 'none' }}
                            onChange={onFileSelected} />
                </div>
            </div>


            <h3 className="UserEditScreen_section-margin">Change your user name</h3>
            {displayNameChangedError &&
                <div style={{color: 'red'}}>Username taken. Please select another one.</div>
            }
            <div style={{display: 'flex', alignItems: 'center'}}>
                <input disabled={displayNameChanged} value={displayName} onChange={onDisplayNameChanged} className="UserEditScreen_profilePic-name"/>
                <div className="new-post__create-post-button__container">
                    <div style={{width: '300px'}}>
                        {displayNameChanged !==true &&
                        <button onClick={onChangeDisplayNameClicked} className="new-post__create-post-button" >Change username
                        </button>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
