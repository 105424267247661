import "./Navigation.css";
import NavigationPill from "../NavigationPill/NavigationPill";
import {useLocation, useNavigate, useNavigation, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    selectAllCommunities,
    selectAnotherUserScreenNewsFeedUser,
    selectPostDetail
} from "../../app/slices/community/communitySelectors";
import NewPostButton from "../NewPostButton/NewPostButton";
import {useAppSelector} from "../../app/hooks";
import {selectUserProfileId} from "../../app/slices/application/applicationSelectors";

const Navigation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const allGroups = useSelector(selectAllCommunities);
    const postDetail = useSelector(selectPostDetail);
    const myUserId = useAppSelector(selectUserProfileId);
    const userScreenData = useAppSelector(selectAnotherUserScreenNewsFeedUser);

    let isGroupPage = false;
    let groupName = "";

    let isNewPostPage = false;
    let isPostDetailsPage = false;


    if (location.pathname.indexOf('/group/') !== -1) {
        isGroupPage = true;
        let selectedGroup = allGroups.find(i => i.id === params.id);
        if (selectedGroup) {
            groupName = selectedGroup.name;
        }
    }

    if (location.pathname.indexOf('/user/') !== -1) {
        isGroupPage = true;
        groupName = userScreenData?.displayName
    }

    if (location.pathname.indexOf('/edit-my-profile') !== -1) {
        isGroupPage = true;
        groupName = "Profile Edit"
    }

    if (location.pathname.indexOf('/new-post') !== -1) {
        isNewPostPage = true;
    }

    if (location.pathname.indexOf('/post/') !== -1) {
        isPostDetailsPage = true;
        if(postDetail?.targetType === 'user'){
            groupName = postDetail?.userProfile?.displayName || '';
        } else {
            let group = allGroups.find(g => g.id === postDetail?.targetId || "") || null;
            if (group) {
                groupName = group.name;
            }
        }
    }

    return (
        <div className="navigation">
            <div className="item">
                <NavigationPill content="Newsfeed" selected={location.pathname === '/newsfeed'}
                                onClick={() => navigate('/newsfeed')}/>
            </div>
            <div className="item">
                <NavigationPill content="Explore" selected={location.pathname === '/explore'}
                                onClick={() => navigate('/explore')}/>
            </div>

            {(isGroupPage || isNewPostPage || isPostDetailsPage) &&
            <div className="NavigationDivider" />
            }

            {isGroupPage &&
            <div className="item">
                <NavigationPill canExpand={true} content={groupName} selected={isGroupPage} onClick={() => {
                }}/>
            </div>
            }

            {isNewPostPage &&
            <div className="item">
                <NavigationPill canExpand={true} content="New post" selected={isNewPostPage} onClick={() => {

                }}/>
            </div>
            }

            {(isPostDetailsPage && groupName) &&
            <div style={{display:'flex'}}>
                <div className="item">
                    <NavigationPill canExpand={true} content={groupName} selected={false} onClick={() => {
                        if(postDetail?.targetType === 'user'){
                            if(postDetail.targetId === myUserId){
                                navigate(`/my-profile`)
                            } else {
                                navigate(`/user/${postDetail?.targetId}`)
                            }
                        } else {
                            navigate(`/group/${postDetail?.targetId}`)
                        }
                    }}/>
                </div>

                <div className="item">
                    <NavigationPill content="Post details" selected={true} onClick={() => {}}/>
                </div>
            </div>
            }
            <div className="spacer"/>
            <div className="new">
                <NewPostButton onClick={() => navigate('/new-post')}/>
            </div>
        </div>
    )
}

export default Navigation;
