import React from 'react';
import './Footer.css';
import facebookLogo from "../../assets/facebook-f-brands.svg";
import instagramLogo from "../../assets/instagram-brands.svg";
import twitterLogo from "../../assets/twitter-brands.svg";
import pinterestLogo from "../../assets/pinterest.svg";
import footerLogo from "../../assets/footer-logo.png";

const Footer = () => {
    const date = new Date().getFullYear();

    const getUrl = (url, params = false)=> {
        if (url) {
            if (url.indexOf('https://www.earth.com') === -1) {
                return `https://www.earth.com${url}`;
            }

            if (typeof params === 'object') {
                const paramsObj = new URLSearchParams(params);
                url = url + '?' + paramsObj.toString();
            }
            return url;
        }
    }

    return (
        <div className={"footer"}>
            <div className={"column"}>
                <div className={"title"}>
                    <img src={footerLogo} alt="logo" />
                </div>

                <div className={"footer_link"}>
                    <a href={getUrl('/about/')}>About Us</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/privacy-policy/')}>Privacy Policy</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/terms-of-service/')}>Terms of Service</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/sitemap_index.xml')}>Site Map</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/staff/')}>Staff</a>
                </div>
            </div>
            <div className={"column"}>
                <div className={"title"}>EARTH.COM</div>
                <div className={"footer_link"}>
                    <a href={getUrl('/news/')}>News</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/video/')}>Videos</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/image/')}>Images</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/earthpedia/')}>Earthpedia</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/take-action-save-earth/')}>Take action</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/shop/')}>Shop</a>
                </div>
            </div>
            <div className={"column"}>
                <div className={"title"}>EARTHPEDIA</div>
                <div className={"footer_link"}>
                    <a href={getUrl('/earthpedia-articles/')}>Articles</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/animal-encyclopedia/')}>Animals Encyclopedia</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/endangered-species-category/endangered-animals/')}>Endangered Animals</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/plant-encyclopedia/')}>Plants Encyclopedia</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/endangered-species-category/endangered-plants/')}>Endangered Plants</a>
                </div>
            </div>
            <div className={"column"}>
                <div className={"title"}>EARTH NEWS</div>
                <div className={"footer_link"}>
                    <a href={getUrl('/news-category/breaking-news/')}>Breaking News</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/news-category/environment/')}>Environment</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/news-category/lifestyle/')}>Lifesyle</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/news-category/voices/')}>Voices</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/news-category/animals/')}>Animals</a>
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/news-category/plants/')}>Plants</a>
                </div>
            </div>
            <div className={"column"}>
                <div className={"title"}>GET IN TOUCH</div>
                <div className={"social_bar"}>
                    <a href="https://www.facebook.com/earth.com/" target="_blank">
                        <div className={"social_link"}>
                            <img src={facebookLogo} />
                        </div>
                    </a>

                    <a href="https://www.instagram.com/earthdotcom/" target="_blank">
                        <div className={"social_link"}>
                            <img src={instagramLogo} />
                        </div>
                    </a>

                    <a href="https://twitter.com/earthdotcom" target="_blank">
                        <div className={"social_link"}>
                            <img src={twitterLogo}/>
                        </div>
                    </a>

                    <a href="https://www.pinterest.com/earthdotcom/" target="_blank">
                        <div className={"social_link"}>
                            <img src={pinterestLogo} />
                        </div>
                    </a>

                    {/* <a href="https://www.earth.com/feed/" target="_blank">
                        <div className={"social_link"}>
                            <img src="/footer/rss-solid.svg" />
                        </div>
                    </a> */}
                </div>
                <div className={"footer_link"}>
                    <a href={getUrl('/contact/')}>Contact Us</a>
                </div>
            </div>

                <div className={"copyright"}>
                    <div className='copyright_date'>&copy; {date} Earth.com. 0.2</div>
                    <div>All rights reserved</div>
                </div>
        </div>
    );
};

export default Footer;
