import "./NewPostGroupSelector.css";
import {useState} from "react";
import {CommunityTarget, GroupsProps} from "../../app/slices/community/communitySlice";
import CommunitySidebarElement from "../CommunitySidebarElement/CommunitySidebarElement";
import {useAppDispatch} from "../../app/hooks";
import {setCommunityTarget} from "../../app/slices/community/communityActions";
import UserGroupAvatar from "../../assets/user_group_avatar.svg";
import ShareIcon from  "../../assets/share_icon.svg";

type NewPostGroupSelectorProps = {
    selectedTarget: CommunityTarget;
    availableGroups: GroupsProps[];
}

const NewPostGroupSelector = (props: NewPostGroupSelectorProps) => {
    const dispatch = useAppDispatch();

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const onGroupSelected = (group?: GroupsProps) => {
        setIsExpanded(false);

        if (!group) {
            dispatch(setCommunityTarget({
                onMyFeed: true,
            }))
        } else {
            dispatch(setCommunityTarget({
                communityId: group.id,
                communityName: group.name,
                communityImage: group.image,
                onMyFeed: false,
            }))
        }
    }

    return (
        <div className="NewPostGroupSelector">
            <div className="NewPostGroupSelectorHead" onClick={() => setIsExpanded(!isExpanded)}>
                {
                    props.selectedTarget.onMyFeed
                        ? <div className="NewPostGroupSelectorHeadWrapper">
                            <CommunitySidebarElement selected={false} onClick={() => {
                            }}
                                                     id={''} image={ShareIcon} name={"On my feed"}/>
                        </div>
                        : props.selectedTarget.communityId
                            ? <div className="NewPostGroupSelectorHeadWrapper">
                                <CommunitySidebarElement selected={false} onClick={() => {
                                }}
                                                         id={props.selectedTarget?.communityId || ''}
                                                         image={props.selectedTarget.communityImage || ""}
                                                         name={props.selectedTarget.communityName || "On my feed"}/>
                            </div>
                            : <div className="NewPostGroupSelectorHeadWrapper">
                                <CommunitySidebarElement selected={false} onClick={() => {
                                }}
                                                         id={props.selectedTarget?.communityId || ''}
                                                         image={props.selectedTarget.communityImage || UserGroupAvatar}
                                                         name={props.selectedTarget.communityName || "Select a community"}/>
                            </div>
                }

            </div>
            {isExpanded &&
            <div className="NewPostGroupSelectorBody">
                <CommunitySidebarElement selected={false} onClick={() => onGroupSelected()}
                                         id="123" image={ShareIcon} name="On my feed"/>
                {props.availableGroups.map(group => {
                    return (
                        <CommunitySidebarElement selected={false} onClick={() => onGroupSelected(group)}
                                                 id={group.id} image={group.image || ""} name={group.name}/>
                    )
                })}
            </div>
            }
        </div>
    )
}

export default NewPostGroupSelector;
