import infoIcon from "../../assets/info_icon.svg";
import {useEffect, useState} from "react";
import {EarthID} from "../../app/earthid";
import "./SpeciesDetailsButton.css";

interface SpeciesDetailsButtonProps {
    hash: string;
    type: string;
}

const SpeciesDetailsButton = (props: SpeciesDetailsButtonProps) => {
    const [url, setUrl] = useState<string>('');

    useEffect(() => {
        const getData = async () => {
            const species = await EarthID.getSpeciesByHash(props.hash || "", props.type || "");

            if (species.group && species.family) {
                let speciesCombined = species.species.toLowerCase().replace(/ /g, '-');
                const url = `https://www.earth.com/${props.type}-encyclopedia/${species.group.toLowerCase()}/${species.family.toLowerCase()}/${speciesCombined}/en/`
                setUrl(url);
            }
        }

        getData().finally();

    }, [])

    if (!props.hash || props.hash.length === 0) {
        return null;
    }

    if (url.length === 0) {
        return null;
    }

    return (
        <div className="post__species-details__button">
            <img src={infoIcon}/>
            <a href={url} target="_blank" className="post__species-details__button-text">Species details</a>
        </div>
    )
}

export default SpeciesDetailsButton;
