import "./Post.css";
import "./InPostScreen.css";
import { postProps } from "../../app/slices/community/communitySlice";
import CircularImage from "../CircularImage/CircularImage";
import animalBadge from "../../assets/species-badge-animal.svg";
import plantBadge from "../../assets/species-badge-plant.svg";
import likeButtonActive from "../../assets/like-icon-active.svg";
import likeButtonInctive from "../../assets/like-icon-inactive.svg";
import commentIcon from "../../assets/comment-icon.svg";
import { commentsCountFormatter, likeCountFormatter } from "../../utils";
import { useNavigate } from "react-router-dom";
import TimeDistance from "../TimeDistance/TimeDistance";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAllCommunities } from "../../app/slices/community/communitySelectors";
import ParsedText from "../ParsedText/ParsedText";
import SpeciesDetailsButton from "../SpeciesDetailsButton/SpeciesDetailsButton";
import { communityLikePostById } from "../../app/slices/community/communityActions";
import EllypsisDropdownMenu from "../EllypsisDropdownMenu/EllypsisDropdownMenu";
import { selectUserProfileId } from "../../app/slices/application/applicationSelectors";

interface PostProps extends postProps {
    inPostScreen?: boolean
    focusNewCommentInput?: () => void;
}

const Post = (props: PostProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const allGroups = useAppSelector(selectAllCommunities);
    const myId = useAppSelector(selectUserProfileId)

    const getBadgeImage = (type) => {
        switch (type) {
            case "plant":
                return plantBadge;
            case "animal":
                return animalBadge;
            default:
                return "";
        }
    }

    const getGroupNameById = (groupId: string) => {
        let group = allGroups.find(g => g.id === groupId);

        return group ? group.name : "";
    }

    const handleCommentButtonClick = () => {
        return props.inPostScreen ? props.focusNewCommentInput!() : navigate(`/post/${props.postId}`)
    }

    const onLikeClicked = () => {
        dispatch(communityLikePostById({ postId: props.postId, likedByMe: props.wasLiked || false }));

    }

    return (
        <div className={`post ${props.inPostScreen ? "in-post-screen" : ""}`}>
            <div className="post__container">
                <div className="post__header">
                    <div className="post__user-details">
                        <CircularImage isProfileImage={true} showPlaceholder={!props.userProfile?.avatarPath}
                            src={props.userProfile?.avatarPath}
                            onClick={() => navigate(`/user/${props.userProfile?.id}`)} />
                        <div className="post__user-details__right">
                            <div className="post__user-name"
                                onClick={() => navigate(`/user/${props.userProfile?.id}`)}>{props.userProfile?.displayName}</div>
                            <div className="post__time-ago">
                                <TimeDistance date={props.datePublished} prefix="Posted" />
                                <div className="post__group-name">in <span
                                    onClick={() => navigate(`/group/${props.targetId}`)}>{getGroupNameById(props.targetId || "")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <EllypsisDropdownMenu targetId={props.postId} targetType={"post"} isReportable={props.userProfile?.id !== myId} isDeletable={props.userProfile?.id === myId} />
                </div>
                <div onClick={() => navigate(`/post/${props.postId}`)} className="post__text">
                    <ParsedText className={"post__text"} value={props.postText} />
                    {/*{props.postText}*/}
                </div>
                {props.postType && <div className="post_species-badge__space" />}
                {props.postImage && <div onClick={() => navigate(`/post/${props.postId}`)} className="post__img">
                    {props.postType && <div className="post__img__species-badge">
                        <img src={getBadgeImage(props.postType)}></img>
                    </div>}
                    <img src={props.postImage} />
                </div>}
                {props.postType && <div className="post__species-details">
                    <div className="post__species-details__name">{props.postSpeciesTitle}</div>

                    <SpeciesDetailsButton hash={props.postSpeciesHash || ""} type={props.postType} />
                </div>}
                <div className="post__interactions-data">
                    <div
                        className="post__interactions-data__likes">{likeCountFormatter(props.nrLikes)}</div>
                    <div>{commentsCountFormatter(props.nrComments)}</div>
                </div>
                <div className="post__separator"></div>
                <div className="post__interactions-buttons">
                    {props.wasLiked ?
                        <div className="post__interaction-button" onClick={onLikeClicked}>
                            <img src={likeButtonActive} />
                            <div className="post__interaction-button__like-text--active">Liked</div>
                        </div>
                        : <div className="post__interaction-button" onClick={onLikeClicked}>
                            <img src={likeButtonInctive} />
                            <div className="post__interaction-button__like-text--inactive">Like</div>
                        </div>}
                    <div className="post__interaction-button">
                        <img className="post__interaction-message-icon" src={commentIcon} />
                        <div onClick={handleCommentButtonClick}
                            className="post__interaction-button__like-text--inactive">Comment
                        </div>
                    </div>
                </div>
                {props.inPostScreen && <div className="post__separator"></div>}
            </div>
        </div>
    )
};

export default Post;
