import { useState } from "react";
import "./RecoverPasswordScreen.css";

const handleSubmit = event => {
    event.preventDefault();
    // TODO Dispatch recover password action
}

const RecoverPasswordScreen = () => {
    const [email, setEmail] = useState<string>("");

    return <div className="recover-password-screen">
        <form onSubmit={handleSubmit}>
            <div className="recover-password-form-container">
                <p>Enter the email used to create this account.</p>
                <p>You will receive an email with further instructions on how tochange your password.</p>
                <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email..." />
                <button type='submit' className='recover-password-button'>Reset password</button>
            </div></form>

    </div>
}

export default RecoverPasswordScreen