export class ConfigPathsDTO {
  AMT_TOKEN: string;
  AMT_REGION: string;
  MAX_DAILY_SNAPS: number;
  API_VERSION: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class ConfigApiDTO {
  api_version: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
