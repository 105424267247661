import {createAsyncThunk} from '@reduxjs/toolkit';
import {
    FacebookAuthProvider,
    getAuth, getRedirectResult,
    GoogleAuthProvider,
    OAuthProvider,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithRedirect,
} from 'firebase/auth';
import * as A from '@amityco/ts-sdk';
import {EarthID} from '../../earthid';
import {ConfigPathsDTO} from "../../earthid/api/config/config.dto";
import {getAllCommunities, getCommunityProfile} from "../community/communityActions";
import {ApplicationStateModel} from "./applicationStateModel";
import {initializeApp} from "firebase/app";
import {updateUserAvatar, updateUserDisplayName} from "../../amity/functions";

const firebaseConfig = {
    apiKey: "AIzaSyDG6h9k-0Mzww8rGwtCEVMWQzn5KrSMLgU",
    authDomain: "earthsnap-60b54.firebaseapp.com",
    databaseURL: "https://earthsnap-60b54-default-rtdb.firebaseio.com",
    projectId: "earthsnap-60b54",
    storageBucket: "earthsnap-60b54.appspot.com",
    messagingSenderId: "449459535432",
    appId: "1:449459535432:web:79cc239dee7466024ae27d",
    measurementId: "G-D0QERG32KM"
};


const app = initializeApp(firebaseConfig);
const firebaseAppAuth = getAuth(app);
console.log("APP INIT")

getRedirectResult(firebaseAppAuth)
    .then((result) => {
        console.log("AUTH RESULT", result)
    }).catch((error) => {
    console.log("AUTH ERROR", error)
});


export const postLoginDataFetch = createAsyncThunk('application/postLoginDataFetch', async (_, {dispatch}) => {
    const firebaseAuth = firebaseAppAuth.currentUser;
    EarthID.setUser(firebaseAuth);

    const firebaseToken = await firebaseAuth?.getIdToken();
    const profile = await dispatch(fetchUserProfile()).unwrap();

    const communityConnected = await A.connectClient({
        userId: profile.userId,
        authToken: profile.socialToken,
        displayName: profile.displayName,
    });

    if (communityConnected) {
        await dispatch(getAllCommunities());
        dispatch(getCommunityProfile({forceRefreshPosts: true}));
    }

    return {
        firebaseToken,
        communityConnected,
        firebaseAuth,
    };
});

export const postLogoutDataWipe = createAsyncThunk('application/postLogoutDataWipe', async () => {
    await A.wipeCache();
    await A.disconnectClient();
    await firebaseAppAuth.signOut();
});

export const updateFirebaseCredentials = createAsyncThunk('application/updateFirebaseCredentials', async () => {
    const firebaseAuth = firebaseAppAuth.currentUser;
    EarthID.setUser(firebaseAuth);

    const firebaseToken = await firebaseAuth?.getIdToken();
    return {firebaseAuth, firebaseToken};
});

export const initialiseRequirements = createAsyncThunk(
    'application/initialiseRequirements',
    async (_, {dispatch}) => {
        const configData: ConfigPathsDTO = await EarthID.getConfigurationData();

        A.createClient(configData.AMT_TOKEN, configData.AMT_REGION);

        const isLoggedIn = firebaseAppAuth.currentUser !== null;

        onAuthStateChanged(firebaseAppAuth, async (user: any) => {
            if (!user) {
                console.log("NO USER")
                // await dispatch(postLogoutDataWipe());
            } else {
                await dispatch(updateFirebaseCredentials());
                await dispatch(postLoginDataFetch());
            }
        });


        return {
            isLoggedIn,
            configData,
        };
    },
);

/**
 * This action handles the main bootstrapping process of the app.
 * These are all tasks that need to be performed before the splash screen is hidden.
 *
 * TODO: @vlladin after EarthId is added to the project, remember to check for connectivity and app force update here.
 *
 */
export const handleAppInit = createAsyncThunk('application/init', async (_, {dispatch}) => {
    const requirements = await dispatch(initialiseRequirements()).unwrap();


    if (requirements.isLoggedIn) {
        console.log("LOGGED IN, POSTLOGIN...")
        await dispatch(postLoginDataFetch());
    } else {
        console.log("NOT LOGGED IN")
        return {
            applicationState: ApplicationStateModel.MustLogin,
        };
    }

    return {
        applicationState: ApplicationStateModel.Ready,
    };
});

export const fetchUserProfile = createAsyncThunk('application/fetchUserProfile', EarthID.getUserProfile);

export const authenticateWithEmail = createAsyncThunk(
    'application/authenticateWithEmail',
    async (data: { email: string; password: string }, redux) => {
        const {email, password} = data;
        if (!email?.trim()?.length || !password?.trim()?.length) {
            throw new Error();
        }
        let authStatus;
        try {
            authStatus = await signInWithEmailAndPassword(firebaseAppAuth, email, password);
        } catch (e: any) {
            throw e;
        }

        await redux.dispatch(postLoginDataFetch());
        return toJSON(authStatus);
    },
);

export const sendResetPasswordEmail = createAsyncThunk(
    'application/resetPassword',
    async (data: { email: string | undefined }) => {
        if (!data.email) {
            throw new Error('No email provided');
        }
        await sendPasswordResetEmail(firebaseAppAuth, data.email);
    },
);


export const authenticateWithSocial = createAsyncThunk(
    'application/authenticateWithSocial',
    async (socialProvider: 'GOOGLE' | 'APPLE' | 'FACEBOOK', redux) => {
        let authPayload;
        switch (socialProvider) {
            case 'GOOGLE': {
                const provider = new GoogleAuthProvider();
                signInWithRedirect(firebaseAppAuth, provider);
                return authPayload;
            }
            case 'FACEBOOK': {
                const provider = new FacebookAuthProvider();
                signInWithRedirect(firebaseAppAuth, provider);
                return authPayload;
            }
            case 'APPLE': {
                const provider = new OAuthProvider('apple.com');
                signInWithRedirect(firebaseAppAuth, provider);
                return authPayload;
            }
        }
    },
);

export const changeUserProfilePicture = createAsyncThunk(
    'application/changeUserProfilePicture',
    async (data: any, redux) => {
        const changeUserProfilePictureStatus = await EarthID.updateUserProfilePicture(data);
        await updateUserAvatar(changeUserProfilePictureStatus.userId, changeUserProfilePictureStatus.profileImage);
        redux.dispatch(getCommunityProfile({}));
        return toJSON(changeUserProfilePictureStatus);
    },
);

export const changeUserDisplayName = createAsyncThunk(
    'application/changeUserDisplayName',
    async (displayName: string, redux) => {
        const changeUserDisplayNameStatus = await EarthID.updateUserDisplayName(displayName);
        await updateUserDisplayName(changeUserDisplayNameStatus.userId, displayName);
        redux.dispatch(getCommunityProfile({}));
        return toJSON(changeUserDisplayNameStatus);
    },
);

function toJSON(data: any) {
    if (!data) {
        return null;
    }

    return JSON.parse(JSON.stringify(data));
}
