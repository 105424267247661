import "./EllypsisDropdownMenu.css";
import EllypsisIcon from "../../assets/ellypsis.svg";
import { useState, useEffect, useRef } from "react";
import { useAppDispatch } from "../../app/hooks";
import { showDeleteModalAction, showReportModalAction2 } from "../../app/slices/application/applicationSlice";

interface EllypsisDropdownMenuProps {
    targetId: string;
    targetType: "comment" | "post" | "user";
    isReportable: boolean;
    isDeletable: boolean;
}

const EllypsisDropdownMenu = (props: EllypsisDropdownMenuProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null)
    const dispatch = useAppDispatch();

    const reportItem = () => {
        dispatch(showReportModalAction2({ targetId: props.targetId, targetType: props.targetType }))
        setIsExpanded(false);
    };

    const deleteItem = () => {
        dispatch(showDeleteModalAction({ targetId: props.targetId, targetType: props.targetType }))
        setIsExpanded(false);
    };

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isExpanded && ref.current && !ref.current.contains(e.target)) {
                setIsExpanded(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isExpanded])

    return <div className="ellypsis-dropdown-menu">
        <img onClick={() => setIsExpanded((prevState) => !prevState)} className="ellypsis-dropdown-menu__ellypsis_icon" src={EllypsisIcon} />
        {isExpanded && <div ref={ref} className="ellypsis-dropdown-menu__expansion">
            {props.isReportable && <div onClick={reportItem} className="ellypsis-dropdown-menu__option">{`Report ${props.targetType}`}</div>}
            {props.isDeletable && <div  onClick={deleteItem} className="ellypsis-dropdown-menu__option">{`Delete ${props.targetType}`}</div>}
        </div>}
    </div>
}

export default EllypsisDropdownMenu