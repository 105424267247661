import "./Layout.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import React, {ReactElement} from "react";
import Loader from "../Loader/Loader";
import CommunitySidebar from "../CommunitySidebar/CommunitySidebar";
import {communitiesMockupData} from "../../mockups/CommunitiesSidebarMockup";
import Navigation from "../Navigation/Navigation";
import {useAppSelector} from "../../app/hooks";
import ReportModal from "../ReportModal/ReportModal";
import DeleteModal from "../DeleteModal/DeleteModal";
import { selectDeleteModalData, selectReportModalData } from "../../app/slices/application/applicationSelectors";

export interface LayoutProps {
    showLoading?: boolean;
    showSidebar?: boolean;
    showNavigation?: boolean;
    children?: ReactElement;
}

const Layout = (props: LayoutProps) => {
    const isReportModalVisible = useAppSelector(selectReportModalData);
    const isDeleteModalVisible = useAppSelector(selectDeleteModalData);

    return (
        <div className="Layout">
            {isReportModalVisible && <ReportModal/>}
            {isDeleteModalVisible && <DeleteModal/>}

            <div className="LayoutNavbar">
                <Navbar/>
            </div>

            {props.showLoading &&
            <Loader/>
            }

            <div className="LayoutChildrenBox">
                <div className="LayoutSidebar">
                    {props.showSidebar &&
                    <CommunitySidebar communities={communitiesMockupData}/>
                    }
                </div>
                <div className="LayoutContent">
                    {props.showNavigation &&
                    <div className="LayoutNavigation">
                        <Navigation/>
                    </div>
                    }
                    {props.children}
                </div>

            </div>
            <div className="LayoutFooter">
                <Footer/>
            </div>
        </div>
    )
}

export default Layout;
