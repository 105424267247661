import CircularImage from "../CircularImage/CircularImage";
import "./CommunitySidebarElement.css";

export type Community = {
    id: string,
    image: string
    name: string
}

interface CommunitySidebarElementProps extends Community {
    selected: boolean;
    onClick: () => void;
}

const CommunitySidebarElement = (props: CommunitySidebarElementProps) => {
    return <div className={`community-sidebar-element ${props.selected ? "selected" : ""}`} onClick={props.onClick}>
        <div className="community-sidebar-element__image">
            <CircularImage isProfileImage={false} src={props.image} withBorder/>
        </div>
        <div className="community-sidebar-element__name">{props.name}</div>
    </div>
}

export default CommunitySidebarElement;
