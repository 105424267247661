import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectReportModalData } from "../../app/slices/application/applicationSelectors";
import { showReportModalAction2 } from "../../app/slices/application/applicationSlice";
import { communityFlagCommentById, communityFlagPostById, communityFlagUserById } from "../../app/slices/community/communityActions";
import "./ReportModal.css";

const ReportModal = () => {
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectReportModalData);

    const hideReportModal = () => {
        dispatch(showReportModalAction2(undefined));
    };

    const onReportItemClicked = () => {
        if (data?.targetType === "comment") {
            dispatch(communityFlagCommentById(data.targetId))
        }
        if (data?.targetType === "user") {
            dispatch(communityFlagUserById(data.targetId))
        }
        if (data?.targetType === "post") {
            dispatch(communityFlagPostById(data.targetId))
        }
        hideReportModal();
    }

    return <div className="report-modal">
        <div className="report-modal__content">
            <div className="report-modal__text">Report {data?.targetType}</div>
            <div className="report-modal__under">You are about to report a {data?.targetType}. Are you sure you want to proceed?</div>
            <div className="report-modal__footer">
                <button className="report-modal__cancel-button" onClick={hideReportModal}>Cancel</button>
                <button className="report-modal__confirm-button" onClick={onReportItemClicked}>Report</button>
            </div>
        </div>
    </div>
}

export default ReportModal;
