import "./NavigationPill.css";

type NavigationPillProps = {
    content: string;
    selected: boolean;
    canExpand?: boolean;
    onClick: () => void;
}

const NavigationPill = (props: NavigationPillProps) => {
    return <div className={`navigation-pill ${props.canExpand ? "navigation-pill__expand" : ""} ${props.selected ? "navigation-pill__selected" : ""}`}
                onClick={props.onClick}>{props.content}</div>
}

export default NavigationPill;
